import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

import EmailIcon from '@material-ui/icons/Email';
import MessageIcon from '@material-ui/icons/Message';
import PhoneIcon from '@material-ui/icons/Phone';

import GitHubIcon from '@material-ui/icons/GitHub';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import PinterestIcon from '@material-ui/icons/Pinterest';
import RedditIcon from '@material-ui/icons/Reddit';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import RoomIcon from '@material-ui/icons/Room';

import SportsBaseballIcon from '@material-ui/icons/SportsBaseball';
import SportsBasketballIcon from '@material-ui/icons/SportsBasketball';
import SportsFootballIcon from '@material-ui/icons/SportsFootball';
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import SportsVolleyballIcon from '@material-ui/icons/SportsVolleyball';
import SportsBaseballOutlinedIcon from '@material-ui/icons/SportsBaseballOutlined';



const styles = (theme) => ({
  icon: {
    fontSize: 18,
    padding: theme.spacing(1),
  },
});


class Icon {

  static Email( props ) {
    const { email, classes } = props;

    if( !email ) return null;

    return (
      <IconButton
        aria-label="email"
        component="a"
        href={ `mailto://${email}` }
        className={classes.icon}
      >
        <EmailIcon fontSize="inherit" />
      </IconButton>
    );
  }

  static ContactForm( props ) {
    const { contactForm, classes } = props;

    if( !contactForm ) return null;

    return (
      <IconButton
        aria-label="email"
        component="a"
        href={ contactForm }
        className={classes.icon}
      >
        <EmailIcon fontSize="inherit" />
      </IconButton>
    );
  }

  static Phone( props ) {
    const { phone, classes } = props;

    if( !phone ) return null;

    return (
      <IconButton
        aria-label="phone"
        component="a"
        href={ `tel://${phone}` }
        className={classes.icon}
      >
        <PhoneIcon fontSize="inherit" />
      </IconButton>
    );
  }

  static Message( props ) {
    const { message, classes } = props;

    if( !message ) return null;

    return (
      <IconButton
        aria-label="message"
        component="a"
        href={ `sms://${message}` }
        className={classes.icon}
      >
        <MessageIcon fontSize="inherit" />
      </IconButton>
    );
  }

  static Verified( props ) {
    const { verified, classes } = props;

    if( !verified ) return null;

    return (
      <IconButton
        aria-label="verified"
        component="a"
        href={ verified }
        className={classes.icon}
      >
        <VerifiedUserIcon fontSize="inherit" />
      </IconButton>
    );
  }

  static Board( props ) {
    const { board, classes } = props;

    if( !board ) return null;

    return (
      <IconButton
        aria-label="verified"
        component="a"
        href={ board }
        className={classes.icon}
      >
        <AssignmentIndIcon fontSize="inherit" />
      </IconButton>
    );
  }

  static Github( props ) {
    const { github, classes } = props;

    if( !github ) return null;

    return (
      <IconButton
        aria-label="github"
        component="a"
        href={`https://github.com/${ github }`}
        className={classes.icon}
      >
        <GitHubIcon fontSize="inherit" />
      </IconButton>
    );
  }

  static Twitter( props ) {
    const { twitter, classes } = props;

    if( !twitter ) return null;

    return (
      <IconButton
        aria-label="twitter"
        component="a"
        href={`https://twitter.com/${ twitter }`}
        className={classes.icon}
      >
        <TwitterIcon fontSize="inherit" />
      </IconButton>
    );
  }

  static Facebook( props ) {
    const { facebook, classes } = props;

    if( !facebook ) return null;

    return (
      <IconButton
        aria-label="twitter"
        component="a"
        href={`https://facebook.com/${ facebook }`}
        className={classes.icon}
      >
        <FacebookIcon fontSize="inherit" />
      </IconButton>
    );
  }

  static Instagram( props ) {
    const { instagram, classes } = props;

    if( !instagram ) return null;

    return (
      <IconButton
        aria-label="instagram"
        component="a"
        href={`https://instagram.com/${ instagram }`}
        className={classes.icon}
      >
        <InstagramIcon fontSize="inherit" />
      </IconButton>
    );
  }

  static LinkedIn( props ) {
    const { linkedin, classes } = props;

    if( !linkedin ) return null;

    return (
      <IconButton
        aria-label="linkedin"
        component="a"
        href={`https://linkedin.com/${ linkedin }`}
        className={classes.icon}
      >
        <LinkedInIcon fontSize="inherit" />
      </IconButton>
    );
  }

  static Pinterest( props ) {
    const { pinterest, classes } = props;

    if( !pinterest ) return null;

    return (
      <IconButton
        aria-label="pinterest"
        component="a"
        href={`https://pinterest.com/${ pinterest }`}
        className={classes.icon}
      >
        <PinterestIcon fontSize="inherit" />
      </IconButton>
    );
  }

  static Reddit( props ) {
    const { reddit, classes } = props;

    if( !reddit ) return null;

    return (
      <IconButton
        aria-label="reddit"
        component="a"
        href={`https://reddit.com/${ reddit }`}
        className={classes.icon}
      >
        <RedditIcon fontSize="inherit" />
      </IconButton>
    );
  }

  static YouTube( props ) {
    const { youtube, classes } = props;

    if( !youtube ) return null;

    return (
      <IconButton
        aria-label="youtube"
        component="a"
        href={`https://youtube.com/${ youtube }`}
        className={classes.icon}
      >
        <YouTubeIcon fontSize="inherit" />
      </IconButton>
    );
  }

  static Map( props ) {
    const { map, classes } = props;

    if( !map ) return null;

    return (
      <IconButton
        aria-label="map"
        component="a"
        href={map}
        className={classes.icon}
      >
        <RoomIcon fontSize="inherit" />
      </IconButton>
    );
  }

  static Baseball( props ) {
    const { baseball, classes } = props;

    if( !baseball ) return null;

    return (
      <IconButton
        aria-label="T&C Baseball"
        component="a"
        href={`https://tandcSports.org/baseball`}
        className={classes.icon}
      >
        <SportsBaseballOutlinedIcon fontSize="inherit" />
      </IconButton>
    );
  }

  static Basketball( props ) {
    const { basketball, classes } = props;

    if( !basketball ) return null;

    return (
      <IconButton
        aria-label="T&C Soccer"
        component="a"
        href={`https://tandcSports.org/basketball`}
        className={classes.icon}
      >
        <SportsBasketballIcon fontSize="inherit" />
      </IconButton>
    );
  }

  static Lacrosse( props ) {
    const { lacrosse, classes } = props;

    if( !lacrosse ) return null;

    return (
      <IconButton
        aria-label="T&C Lacrosse"
        component="a"
        href={`https://tandcSports.org/lacrosse`}
        className={classes.icon}
      >
        <SportsSoccerIcon fontSize="inherit" />
      </IconButton>
    );
  }

  static Football( props ) {
    const { football, classes } = props;

    if( !football ) return null;

    return (
      <IconButton
        aria-label="T&C Flag Football"
        component="a"
        href={`https://tandcSports.org/football`}
        className={classes.icon}
      >
        <SportsFootballIcon fontSize="inherit" />
      </IconButton>
    );
  }

  static Soccer( props ) {
    const { soccer, classes } = props;

    if( !soccer ) return null;

    return (
      <IconButton
        aria-label="T&C Soccer"
        component="a"
        href={`https://tandcSports.org/soccer`}
        className={classes.icon}
      >
        <SportsSoccerIcon fontSize="inherit" />
      </IconButton>
    );
  }

  static Softball( props ) {
    const { softball, classes } = props;

    if( !softball ) return null;

    return (
      <IconButton
        aria-label="T&C Softball"
        component="a"
        href={`https://tandcSports.org/softball`}
        className={classes.icon}
      >
        <SportsBaseballIcon fontSize="inherit" />
      </IconButton>
    );
  }

  static Volleyball( props ) {
    const { volleyball, classes } = props;

    if( !volleyball ) return null;

    return (
      <IconButton
        aria-label="T&C Volleyball"
        component="a"
        href={`https://tandcSports.org/volleyball`}
        className={classes.icon}
      >
        <SportsVolleyballIcon fontSize="inherit" />
      </IconButton>
    );
  }
}

export default withStyles(styles)(Icon);
