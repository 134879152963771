import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles, createStyles } from '@material-ui/core/styles';
import MaterialAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Box from '@material-ui/core/Box';

import Search from '../input/Search';
//import UserTray from '../Navigation/UserTray';
import Logo from './Logo';
import QuickLinks from '../Navigation/QuickLinks';
import SocialMenu from '../Navigation/SocialMenu';
import NotificationBar from '../Notification/NotificationBar';
import NavigationMenu from '../Navigation/NavigationMenu';

import Badge from '@material-ui/core/Badge';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';


const logo= {
  src: "/includes/images/tandcsports.png",
  alt: "T&C Sports Logo",
};

const menuItems = {
  desktop: {
    buttons: [
      {
        label: "Our Sports",
        menu: {
          items: [
            {
              label: "Baseball",
              link: "/baseball",
              menu: {
                items: [
                  {
                    label: "T&C Baseball Program Info",
                  },
                  {
                    label: "Baseball Board",
                  },
                  {
                    label: "Divisions",
                  },
                  {
                    label: "Schedule",
                  },
                  {
                    label: "Documents",
                  },
                  {
                    label: "Coaching Corner",
                  },
                  {
                    label: "Officiating Resources",
                  },
                ],
              },
              image: '/includes/images/baseball2.jpg',
            },
            {
              label: "Basketball",
              link: "/basketball",
              menu: {
                items: [
                  {
                    label: "T&C Basketball Program Info",
                  },
                  {
                    label: "Basketball Board",
                  },
                  {
                    label: "Divisions",
                  },
                  {
                    label: "Schedule",
                  },
                  {
                    label: "Documents",
                  },
                  {
                    label: "Coaching Corner",
                  },
                  {
                    label: "Officiating Resources",
                  },
                ],
              },
              image: '/includes/images/baseball.jpg',
            },
            {
              label: "Flag Football",
              link: "/football",
              menu: {
                items: [
                  {
                    label: "T&C Flag Football Program Info",
                  },
                  {
                    label: "Football Board",
                  },
                  {
                    label: "Divisions",
                  },
                  {
                    label: "Schedule",
                  },
                  {
                    label: "Documents",
                  },
                  {
                    label: "Coaching Corner",
                  },
                  {
                    label: "Officiating Resources",
                  },
                ],
              },
              image: '/includes/images/football.jpg',
            },
            {
              label: "Lacrosse",
              link: "/lacrosse",
              menu: {
                items: [
                  {
                    label: "T&C Lacrosse Program Info",
                  },
                  {
                    label: "Lacrosse Board",
                  },
                  {
                    label: "Divisions",
                  },
                  {
                    label: "Schedule",
                  },
                  {
                    label: "Documents",
                  },
                  {
                    label: "Coaching Corner",
                  },
                  {
                    label: "Officiating Resources",
                  },
                ],
              },
              image: '',
            },
            {
              label: "Soccer",
              link: "/Soccer",
              menu: {
                items: [
                  {
                    label: "T&C Soccer Program Info",
                  },
                  {
                    label: "Soccer Board",
                  },
                  {
                    label: "Divisions",
                  },
                  {
                    label: "Schedule",
                  },
                  {
                    label: "Documents",
                  },
                  {
                    label: "Coaching Corner",
                  },
                  {
                    label: "Officiating Resources",
                  },
                ],
              },
              image: '/includes/images/soccer.jpg',
            },
            {
              label: "Softball",
              link: "/Softball",
              menu: {
                items: [
                  {
                    label: "T&C Softball Program Info",
                  },
                  {
                    label: "Softball Board",
                  },
                  {
                    label: "Divisions",
                  },
                  {
                    label: "Schedule",
                  },
                  {
                    label: "Documents",
                  },
                  {
                    label: "Coaching Corner",
                  },
                  {
                    label: "Officiating Resources",
                  },
                ],
              },
              image: 'https://cdn3.sportngin.com/attachments/photo/b20e-137634129/batter_large.jpg',
            },
            {
              label: "Volleyball",
              link: "/Volleyball",
              menu: {
                items: [
                  {
                    label: "T&C Volleyball Program Info",
                  },
                  {
                    label: "Volleyball Board",
                  },
                  {
                    label: "Divisions",
                  },
                  {
                    label: "Schedule",
                  },
                  {
                    label: "Documents",
                  },
                  {
                    label: "Coaching Corner",
                  },
                  {
                    label: "Officiating Resources",
                  },
                ],
              },
              image: 'https://cdn2.sportngin.com/attachments/photo/b2fa-135472618/J8B_0596_large.jpg',
            },
          ]
        }
      },
      {
        label: "Our Campus",
        link: "/soccer",
        menu: {
          items: [
            {
              label: "Field Status",
              link: "/fieldstatus",
              menu: {
                items: [],
              },
              image: 'http://thormobile.stormdata.org/global/images/thorguardlogo_blank.png',
            },
            {
              label: "Maps & Directions",
              link: "/map",
              menu: {
                items: [],
              },
              image: '/includes/images/baseball2.jpg',
            },
            {
              label: "Facilities",
              link: "/facilities",
              menu: {
                items: [
                  {
                    label: "Parking",
                  },
                  {
                    label: "Accessibility",
                  },
                  {
                    label: "Concessions",
                  },
                  {
                    label: "Recycling",
                  },
                  {
                    label: "Gyms",
                  },

                ],
              },
              image: '/includes/images/baseball2.jpg',
            },
            {
              label: "Field Rentals",
              link: "/map",
              menu: {
                items: [],
              },
              image: 'https://cdn4.sportngin.com/attachments/photo/dd7d-144005392/Fitness_Fun_Image_large.png',
            },
            {
              label: "Campus Policies",
              link: "/policies",
              menu: {
                items: [
                  {
                    label: "lightning",
                  },
                  {
                    label: "Zero Tolerance",
                  },
                  {
                    label: "Pet Policy",
                  },
                  {
                    label: "Incident Reports",
                  },
                  {
                    label: "Concussions",
                  },
                  {
                    label: "Abuse Prevention",
                  },
                ],
              },
              image: 'https://cdn2.sportngin.com/attachments/photo/350b-144005412/Food_for_thought_Image_large.png',
            },
            {
              disabled: true,
            },
            {
              disabled: true,
            },
          ]
        }
      },
      {
        label: "Give Back",
        menu: {
          items: [
            {
              label: "Coaching",
              link: "/coaching",
              menu: {
                items: [
                  {
                    label: "Why Coach",
                  },
                  {
                    label: "Coach Application",
                  },
                  {
                    label: "Coach Resources",
                  },
                ],
              },
              image: '',
            },
            {
              label: "Volunteering",
              link: "/volunteering",
              menu: {
                items: [
                  {
                    label: "Information",
                  },
                  {
                    label: "Volunteer Spotlight",
                  },
                ],
              },
              image: 'https://cdn1.sportngin.com/attachments/photo/131f-143171731/Amplify_Thank_you_large.png',
            },
            {
              label: "Donating",
              link: "/donate",
              menu: {
                items: [
                  {
                    label: "Why Donate",
                  },
                  {
                    label: "Donate Now",
                  },
                  {
                    label: "T&C Partners",
                  },
                  {
                    label: "Amazon Smile",
                  },
                  {
                    label: "Corporate Donation",
                  },
                ],
              },
              image: 'https://cdn1.sportngin.com/attachments/photo/131f-143171731/Amplify_Thank_you_large.png',
            },
            {
              label: "Sponsor T&C",
              link: "/sponsor",
              menu: {
                items: [
                  {
                    label: "Become A Sponsor",
                  },
                  {
                    label: "Current Sponsors",
                  },
                ],
              },
              image: 'https://cdn1.sportngin.com/attachments/photo/131f-143171731/Amplify_Thank_you_large.png',
            },
            {
              label: "Golf Tournament",
              link: "/golftournament",
              menu: {
                items: [
                  {
                    label: "Play",
                  },
                  {
                    label: "Sponsor",
                  },
                  {
                    label: "Event Details",
                  },
                ],
              },
              image: '/includes/images/golfFlyer.png',
            },
            {
              disabled: true,
            },
            {
              disabled: true,
            },
          ]
        }
      },
      {
        label: "About T&C",
        link: "/about",
        menu: {
          items: [
            {
              label: "Optimist Club",
              link: "/",
              menu: {
                items: [
                  {
                    label: "Mission Statement",
                  },
                  {
                    label: "Policies & Bylaws",
                  },
                  {
                    label: "Optimist International",
                  },
                  {
                    label: "Board Members",
                  },
                  {
                    label: "Contact Board",
                  },
                  {
                    label: "Club Members",
                  },
                  {
                    label: "Club Application",
                  },
                ],
              },
              image: '',
            },
            {
              label: "Support Optimist",
              link: "/",
              menu: {
                items: [
                  {
                    label: "Christmas Tree Lot",
                  },
                  {
                    label: "Volunteer Info",
                  },
                ],
              },
              image: '',
            },
            {
              label: "T&C Office",
              link: "/",
              menu: {
                items: [
                  {
                    label: "Staff",
                  },
                  {
                    label: "Hours",
                  },
                  {
                    label: "Contact Us",
                  },
                  {
                    label: "Location",
                  },
                ],
              },
              image: '',
            },
            {
              label: "Policies & Forms",
              link: "/",
              menu: {
                items: [
                  {
                    label: "General Policies",
                  },
                  {
                    label: "Financial Aid",
                  },
                  {
                    label: "Refunds",
                  },
                  {
                    label: "Background Check",
                  },
                  {
                    label: "Privacy",
                  },
                  {
                    label: "Reimbursement",
                  },
                ],
              },
              image: '/includes/images/baseball2.jpg',
            },
            {
              disabled: true,
            },
            {
              disabled: true,
            },
            {
              disabled: true,
            },
          ]
        }
      },
      {
        label: "T&C Community",
        link: "/",
        menu: {
          items: [
            {
              label: "Calendar",
              link: "/",
              menu: {
                items: [],
              },
              image: '',
            },
            {
              label: "Player Development",
              link: "/",
              menu: {
                items: [],
              },
              image: '',
            },
            {
              label: "Play at Home",
              link: "/",
              menu: {
                items: [],
              },
              image: '',
            },
            {
              label: "Camps & Events",
              link: "/",
              menu: {
                items: [],
              },
              image: '',
            },
            {
              label: "Newsletters",
              link: "/",
              menu: {
                items: [],
              },
              image: '',
            },
            {
              label: "Miracle League",
              link: "/",
              menu: {
                items: [],
              },
              image: '',
            },
            {
              label: "Social Media",
              link: "/",
              menu: {
                items: [],
              },
              image: '',
            },
          ]
        }
      },
    ]
  }
};


const styles = (theme) => createStyles({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.main,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  sectionNavMenu: {
    backgroundColor: "white",
    height: "64px",
  }
});

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};



class AppBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      anchorEl: null,
      setAnchorEl: null,
      navigation: null,
    }

  }

  componentDidMount() {
    const { hostConfig } = this.props;

    if ( hostConfig && hostConfig.navigation ) {
      const onSnapshot = hostConfig.navigation.onSnapshot((navDoc) => {
        this.setState({
          navigation: {
            uid: navDoc.id,
            ...navDoc.data(),
          },
          loaded: true,
          currentConfig: hostConfig,
        });
      });
      this.setState({ onSnapshot: onSnapshot });
    }
  }

  componentDidUpdate() {

  }

  componentWillUnmount() {
    if( this.state.onSnapshot )
      this.state.onSnapshot();
  }

  setAnchorEl( target ) {

  }

  setMobileMoreAnchorEl( target ) {

  }

  render() {
    const { classes } = this.props;
    //const navigation = hostConfig ? hostConfig.navigation : null;

    const { anchorEl, mobileMoreAnchorEl } = this.state;

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
      this.setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
      this.setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
      this.setAnchorEl(null);
      handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
      this.setMobileMoreAnchorEl(event.currentTarget);
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
      <Menu
        anchorEl={ anchorEl }
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={ menuId }
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={ isMenuOpen }
        onClose={ handleMenuClose }
      >
        <MenuItem onClick={ handleMenuClose }>Profile</MenuItem>
        <MenuItem onClick={ handleMenuClose }>My account</MenuItem>
      </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
      <Menu
        anchorEl={ mobileMoreAnchorEl }
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={ mobileMenuId }
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={ isMobileMenuOpen }
        onClose={ handleMobileMenuClose }
      >
        <MenuItem>
          <IconButton aria-label="show 4 new mails" color="inherit">
            <Badge badgeContent={4} color="secondary">
              <MailIcon />
            </Badge>
          </IconButton>
          <p>Messages</p>
        </MenuItem>
        <MenuItem>
          <IconButton aria-label="show 11 new notifications" color="inherit">
            <Badge badgeContent={11} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <p>Notifications</p>
        </MenuItem>
        <MenuItem onClick={ handleProfileMenuOpen }>
          <IconButton
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <p>Profile</p>
        </MenuItem>
      </Menu>
    );

    return (
      <Box p={4}>
        <CssBaseline />
        <ElevationScroll { ...this.props }>
          <Box display="flex" flexGrow={1} pr={8}>
            <MaterialAppBar >
              <Toolbar disableGutters>
                <Box classes={{ root: classes.sectionMobile }}>
                  <IconButton
                    edge="start"
                    classes={{ root: classes.menuButton }}
                    color="inherit"
                    aria-label="open drawer"
                  >
                    <MenuIcon />
                  </IconButton>
                </Box>
                <Box flexGrow={1} classes={{ root: classes.sectionDesktop }}>
                  <NotificationBar color="secondary"/>
                </Box>
                <Box pr={4}>
                  <Search />
                </Box>

                <Box classes={{ root: classes.sectionDesktop }}>
                  {/* <UserTray pr={2} pl={2} /> */}
                </Box>
                <Box classes={{ root: classes.sectionMobile }}>
                  <IconButton
                    aria-label="show more"
                    aria-controls={ mobileMenuId }
                    aria-haspopup="true"
                    onClick={ handleMobileMenuOpen }
                    color="inherit"
                  >
                    <MoreIcon />
                  </IconButton>
                </Box>
              </Toolbar>
              <Box
                classes={{ root: clsx(classes.sectionDesktop, classes.sectionNavMenu) }}
                display="flex"
                flexGrow={1}
              >
                <Logo src={ logo.src } alt={ logo.alt } shrinkRight />
                <NavigationMenu menuItems={ menuItems } display="flex" flexGrow={1} />
                <QuickLinks />
              </Box>
              <Box classes={{ root: classes.sectionDesktop }}>
                <SocialMenu />
              </Box>
            </MaterialAppBar>
            { renderMobileMenu }
            { renderMenu }
          </Box>
        </ElevationScroll>
      </Box>
    );
  }
}

export default withStyles(styles)(AppBar);
