import React, { Component } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';



const styles = (theme) => createStyles({
  root: {
  },
  text: {
  },
});



class Subtitle extends Component {
  static defaultProps = {
    justifyContent: "center",
    variant: "subtitle1",
    component: "subtitle1",
    align: "center",
  }

  render() {
    const { classes, children, bgcolor, justifyContent, variant, ...props } = this.props;

    return (
      <Box
        display="flex"
        classes={{ root: classes.root }}
        bgcolor={ bgcolor }
        justifyContent={ justifyContent }
        { ...props }
      >
        <Typography
          variant={ variant || "body1" }
          { ...props }
          classes={{ root: classes.text }}
        >
          { children }
        </Typography>
      </Box>
    );
  }

}

export default withStyles(styles)(Subtitle);
