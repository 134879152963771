import React, { Component } from 'react';


import styles from './SocialMenu.module.css';

class SocialMenu extends Component {
  render() {
    return (
      <div className={ styles.root }>
        <a href="https://www.facebook.com" className={ styles.facebook }><i className="fab fa-facebook"></i></a>
        <a href="https://www.instagram.com" className={ styles.instagram }><i className="fab fa-instagram"></i></a>
        <a href="https://www.twitter.com" className={ styles.twitter }><i className="fab fa-twitter"></i></a>
        <a href="https://www.youtube.com" className={ styles.youtube }><i className="fab fa-youtube"></i></a>
        <a href="mailto:" className={ styles.email }><i className="fas fa-envelope"></i></a>
      </div>
    );
  }
}

export default SocialMenu;
