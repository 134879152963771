import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import WarningIcon from '@material-ui/icons/Warning';
import Chip from '@material-ui/core/Chip';
import { green, yellow, red, black } from '@material-ui/core/colors';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  chip: {
    margin: "1rem",
  },
});

function FieldStatusIcon(props) {
  const { status } = props;
  switch( status ) {
    case true: return( <DoneOutlineIcon style={{ color: green[500] }}/> );
    case false: return( <ErrorOutlineIcon style={{ color: red[500] }}/> );
    default: return( <WarningIcon style={{ color: yellow[500] }}/> );
  }
}

function SportStatus(fields) {
  let status = true;
  status = fields.every( (field) => field.status === true )
  return true;
}

function ChipColor(colorStr) {
  switch( colorStr ) {
    case "green": return( green[500] );
    case "yellow": return( yellow[500] );
    case "red": return( red[500] );
    default: return( null );
  }
}

function Row(props) {
  const { row } = props;
  let [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const status = row.fields.every( (field) => field.status === true ) ? true
                  :row.fields.every( (field) => field.status === false ) ? false
                  :null;
  if( status === null ) open = true;

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            { open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography variant="body2">{ row.label }</Typography>
        </TableCell>
        <TableCell align="right"><FieldStatusIcon status={ status }/></TableCell>
        <TableCell align="right">
          { row.chips?.map( (chip) => (
              <Chip
                label={ chip.label }
                className={ classes.chip }
                style={{ backgroundColor: ChipColor(chip.color) }}
              />
            )
          )}
          <Typography variant="body2">{ row.notes }</Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell/>
        <TableCell/>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
          <Collapse in={ open } timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="fields">
                <TableHead>
                  <TableRow>
                    { row.fields.map( (field, key) => (
                      <TableCell align="center">
                        { field.label }
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    { row.fields.map( (field, key) => (
                      <TableCell component="th" scope="row" align="center">
                        <FieldStatusIcon status={ field.status } />
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

export default function FieldStatusTable(props) {
  const { sports } = props;
  return (
    <Box>
      <Typography variant="body2" align="right">
        Updated 23 Minutes Ago (6/24/2020 11:20 AM)
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>T&C Fields</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="right">Notes</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sports.map((sport, key) => (
              <Row key={ key } row={sport} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="body2" align="right">
        Fields open ONLY for authorized use arranged through T&C Sports' Office with director@tandcsports.org. Office open by appointment only through office@tandcsport.org.
      </Typography>
    </Box>
  );
}
