import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { withStyles, createStyles } from '@material-ui/core/styles';

import MenuPopup from '../MenuPopup';
import TabbedMenu from '../../../TabbedMenu';

//UI change timeout
const timeoutLength = 300;

const styles = createStyles({
  root: {

  },
  paper: {
    border: '1px solid #d3d4d5',
  },
  label: {

  },
  popup: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    }
  }
});

class MenuButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };

    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  openMenu(event) {
    clearTimeout(this.state.timeout);
    if( !this.state.anchorEl ) this.setState({ anchorEl: event.currentTarget });
  }

  closeMenu(event) {
    clearTimeout(this.state.timeout);
    const timeout = setTimeout(() => {
      this.setState({
        anchorEl: null,
      });
    }, timeoutLength);

    this.setState({ timeout: timeout });
  }


  render() {
    var { children, label, menu, classes, anchorEl, open, ...props } = this.props;


    anchorEl = anchorEl ? anchorEl : this.state.anchorEl;

    // return focus to the button when we transitioned from !open -> open
    /*const prevOpen = React.useRef(open);
    React.useEffect(() => {
      if (prevOpen.current === true && open === false) {
        anchorRef.current.focus();
      }

      prevOpen.current = open;
    }, [open]);*/

    return (
      <React.Fragment>
        <Button
          ref={ anchorEl }
          aria-owns={open ? 'menu-items' : null}
          aria-haspopup="true"
          onClick={ this.openMenu }
          onMouseOver={ this.openMenu }
          onMouseLeave={ this.closeMenu }
          classes={{ root: classes.root, label: classes.label }}
          { ...props }
        >
          { children || label }
        </Button>
        {
          anchorEl && menu && menu.items && (
            <MenuPopup
              anchorEl={ anchorEl }
              placement="bottom-start"
              disablePortal={true}
              //open={ open }
              onClickAway={ this.closeMenu }
              onMouseOver={ this.openMenu }
              onMouseLeave={ this.closeMenu }
              classes={{ root: classes.popup }}
            >
              <TabbedMenu menuItems={ menu.items } />
            </MenuPopup>
          )
        }
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(MenuButton);
