import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles, createStyles } from '@material-ui/core/styles';

import ContentFooter from './ContentFooter';
import SiteFooter from './SiteFooter';


const styles = (theme) => createStyles({
  siteFooter: {
    backgroundColor: theme.palette.tcYellow.main,
  },
  rightContent: {
    backgroundColor: theme.palette.primary.main,
  },
  leftContent: {
    backgroundColor: "",
  }
});

class Footer extends Component {

  render() {
    const { classes } = this.props;

    return(
      <Grid container component="footer">
        <Grid item xs={12}>
          <ContentFooter {...this.props} classes={{ leftContent: classes.leftContent, rightContent: classes.rightContent }} />

        </Grid>
        <Grid item xs={12}>
          <SiteFooter classes={{ root: classes.siteFooter }}/>
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(Footer);
