import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import MenuPanel from './MenuPanel';


const styles = (theme) => createStyles({
  root: {
    maxHeight: '30rem',
  },
  menuButtonLabel2: {
    fontSize: '1rem',
    fontWeight: 600,
    textAlign: 'left',
    alignItems: 'left',
    justify: 'left',
    wordSpacing: '120px',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
    '&:focus': {
      color: theme.palette.secondary.main,
    },
  },
  menuTab: {
    justify: 'start',
    width: '120px',
    '&:hover': {
      backgroundColor: "rgba(0,0,0,0)",
    },
    '&:focus': {
      outline: "none",
    },
  },
  tabs: {

  },
  tab: {

  },
  panel: {

  },
  image: {

  }
});

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}




class TabbedMenu extends Component {
  constructor( props ) {
    super( props );
    this.state = {
      value: "",
    }

    this.onChange = this.onChange.bind(this);
  }

  onChange(event, newValue) {
    this.setState({ value: newValue });
    console.log(newValue);
  }

  render() {
    const { menuItems, classes } = this.props;
    const value = (this.state.value !== undefined ) ? this.state.value : this.props.value;

    return (
      <Box classes={{ root: classes.root }} display='flex' flexGrow={1}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={ value }
          onChange={ this.onChange }
          aria-label="Vertical tabs example"
          classes={{ root: classes.tabs }}
        >
          {
            menuItems && menuItems.map(( item, key ) =>
              <Tab
                { ...item }
                key={ key }
                onChange={ this.onChange }
                onMouseOver={ (e) => this.onChange(e, key) }
                { ...a11yProps(key) }
                classes={{ root: classes.tab }}
              />
            )
          }
          {/*<Tab key={ -1 } hidden/>*/}
        </Tabs>
        {
          menuItems && menuItems.map(( item, key ) =>
            <Box
              role="tabpanel"
              id={`vertical-tabpanel-${key}`}
              aria-labelledby={`vertical-tab-${key}`}
            >
              <MenuPanel
                hidden={ value !== key }
                classes={{ root: classes.panel }}
                { ...item }
              />
            </Box>
          )
        }
      </Box>
    );
  }
}




export default withStyles(styles)(TabbedMenu);
