import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { withStyles, createStyles } from '@material-ui/core/styles';

import SubscribeForm from '../../input/SubscribeForm';
import ContactInfo from '../../ContactInfo';
import FooterLinks from './FooterLinks';


const styles = (theme) => createStyles({
  root: {
  },
  left: {
    background: theme.palette.primary.light,
  },
  subscribeHeading: {
    color: theme.palette.secondary,
  },
  subscribeTextField: {
    color: theme.palette.primary.contrastText,
  },
  leftContent: {
    justifyContent: "flex-end",
    [theme.breakpoints.down('md')]: {
      justifyContent: "center",
    },
    background: theme.palette.primary.light,
  },
  rightContent: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
});


class ContentFooter extends Component {
  render() {
    const { contactInfo, links, classes } = this.props;

    return(
      <Grid
        container
        justify="center"
        alignItems="stretch"
        classes={{ root: classes.root }}
        direction="row-reverse"
      >
        <Grid item xs={12} md={6} classes={{ root: classes.rightContent }}>

          <Box
            p={1}
            className={ styles.footerSubscribe }
            flexGrow={1}
          >
            <Box pl={5}>
              <SubscribeForm
                p={5}
                classes={{
                  emailTextField: classes.subscribeTextField,
                  heading: classes.subscribeHeading,
                }}
              />
            </Box>
          </Box>

        </Grid>
        <Grid item xs={12} md={6} classes={{ root: classes.leftContent }}>
          <Grid container>

            <Grid item xs={12} sm={8} classes={{ root: classes.contactInfo }}>
              <ContactInfo
                { ...contactInfo }
              />
            </Grid>

            <Grid item xs={12} sm={4} classes={{ root: classes.footerLinks }}>
              <Box pt={5}>
                <FooterLinks links={ links }/>
              </Box>
            </Grid>

          </Grid>
        </Grid>
      </Grid>
    )
  }
}


export default withStyles(styles)(ContentFooter);
