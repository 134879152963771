import React, { Component } from 'react';

import AppBar from '../AppBar';


class Header extends Component {
  constructor( props ) {
    super( props );

    this.state = {
      navMenu: null,
      loading: false,
    }

  }

  render() {
    const { hostConfig, ...props } = this.props;

    return (
      <AppBar hostConfig={ hostConfig } { ...props } />
    );
  }

}

export default Header;
