import React, { Component } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';


const styles = ({ palette }: Theme) => createStyles({
  root: {

  },
  linkText: {
    color: palette.primary.main,
    textTransform: "capitalize",
    textDecoration: "none",
    '&:hover': {
      color: palette.secondary.main,
      textDecoration: "none",
    },
  },
  title: {
    textDecoration: "none",
    color: palette.secondary.main,
    '&:hover': {
      color: palette.primary.main,
      textDecoration: "none",
    },
    '&:focus': {
      color: palette.primary.main,
    },
  },
  description: {
    textDecoration: "none",
    color: palette.primary.main,
  },
  button: {
    textDecoration: "none",
    display: "block",
    '&:hover': {
      backgroundColor: "rgba(0,0,0,0)",
      textDecoration: "none",
    },
    '&:focus': {
      outline: "none",
    },
  },
});


class IconCard extends Component {
  render() {
    const { title, description, classes, children } = this.props;

    return(
      <Link classes={{ root: classes.linkText }} align="center" p={2} color="black">
        { children }
        <CardContent classes={{ root: classes.linkText }}>
          <Typography variant="h4" component="p" align="center" classes={{ root: classes.title}}>
            { title }
          </Typography>
          <Typography variant="body1" color="white" component="p"  align="center" classes={{ root: classes.description}}>
            { description }
          </Typography>
        </CardContent>
      </Link>
    )
  }
}

export default withStyles(styles)(IconCard);
