import React, { Component } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { fade } from '@material-ui/core/styles/colorManipulator';


const styles = (theme) => createStyles({
  root: {
    //maxWidth: "345px",
    //height: "100%",
  },
  base: {
    height: "100%",
  },
  darken: {
    position: "relative",
    "&::after": {
      content: "",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "block",
      background: "rgba(0, 0, 0, .4)",
    },
  },
  overlay: {
      content: "",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      background: "rgba(0, 0, 0, .4)",
      height: "500px",
      '&:hover': {
        backgroundColor: fade("#000",.5),
      }
  },
  media: {
    height: "500px",
  },
  overlayItem: {
    width: "100%",
  },
  overlayTextMain: {
    width: "100%",
    color: theme.palette.primary.contrastText,
  },
  overlayTextSecondary: {
    width: "100%",
    color: theme.palette.primary.contrastText,
  },
  cardButton: {
    backgroundColor: theme.palette.primary.main,
    width: "100%",
  },
  button: {
    width: "100%",
    color: theme.palette.primary.contrastText,
  },
});


class StoryCard extends Component {

  render() {
    const { image, title, subtitle1, subtitle2, classes, ...props } = this.props;

    return(
      <Card
        classes={{ root: classes.root }}
        { ...props }
        bgcolor="gray"
      >
        <CardActionArea classes={{ root:classes.base }}>
          <CardMedia
            classes={{ root: classes.media }}
            component="Image"
            alt=""
            image={ image }
          />
            <Grid
              container
              classes={{ root: classes.overlay }}
              display="flex"
              direction="column"
              alignItems="stretch"
              justify="space-between"
            >
              <Grid item
                classes={{ root: classes.overlayItem }}
              >
                <Typography
                  variant="subtitle1"
                  component="p"
                  align="center"
                  classes={{ root: classes.overlayTextSecondary }}
                >
                  { subtitle1 }
                </Typography>
              </Grid>

              <Grid item
                classes={{ root: classes.overlayItem }}
              >
                <Typography
                  variant="h4"
                  component="p"
                  align="center"
                  classes={{ root: classes.overlayTextMain }}
                >
                  { title }
                </Typography>
              </Grid>

              <Grid item
                classes={{ root: classes.overlayItem }}
              >
                <Typography
                  variant="subtitle1"
                  component="p"
                  align="center"
                  classes={{ root: classes.overlayTextSecondary }}
                >
                  { subtitle2 }
                </Typography>
              </Grid>
            </Grid>

          <CardActions disableSpacing classes={{ root: classes.cardButton }}>
            <Button classes={{ root: classes.button }}>
              Learn More
            </Button>
          </CardActions>
        </CardActionArea>
      </Card>

    )
  }
}

export default withStyles(styles)(StoryCard);
