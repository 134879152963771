import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

export default function TextBox(props) {
  const { children } = props;

  return (
    <Paper>
      <Typography variant="body1">
        { children }
      </Typography>
    </Paper>
  );
}
