import { Component } from 'react';


class Name extends Component {

  static defaultProps = {
    commonName: "T&C Sports",
    legalName: "Town and Country Optimist Foundation",
  }

  onClick(event) {

  }

  render() {
    let { legal, legalName, commonName } = this.props;

    if (legal) return legalName;
    return commonName;
  }
}


export default Name;
