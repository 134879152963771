import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import ContactCard from '../Card/ContactCard';



const styles = (theme) => ({
  container: {
    margin: theme.spacing(2, 0, 4),
  },
});

export function Group(props) {
  const { name, description, classes, members } = props;
  return (
    <div>
      <Typography gutterBottom component="h2" variant="h2">
        {name}
      </Typography>
      <Typography>{description}</Typography>
      <Grid container spacing={2} className={classes.container}>
        {members.map((member) => (
          <ContactCard sm={12} md={6} lg={4} { ...member } />
        ))}
      </Grid>
    </div>
  );
}

Group.propTypes = {
  classes: PropTypes.object.isRequired,
  description: PropTypes.string.isRequired,
  members: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

function Team(props) {
  const { groups } = props;
  return (
    <div>
      {groups.map((group) => (
        <Group
          name={ group.name }
          description={ group.description }
          members={ group.members }
          { ...props }
        />
      ))}
    </div>
  );
}

export default withStyles(styles)(Team);
