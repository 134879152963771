import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import css from './NotificationBar.module.css';
import TranslateButton from '../../Button/Translate';

const styles = (theme) => createStyles({
  root: {

  },
  NotificationBar: {
    backgroundColor: theme.palette.secondary.main,
  },
  tagline: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  NotificationButton: {
    color: theme.palette.secondary.contrastText,
    fontWeight: 600,
    '&:hover': {
      backgroundColor: "rgba(0,0,0,0)",
    }
  }

});



class NotificationBar extends Component {

  render() {
    const { classes, ...props } = this.props;

    return (
      <Box className={css.Notification} display="flex" classes={{ root: classes.root }} { ...props }>
        <Box className={css.NotificationBar} display="flex" pl={2} pr={2} classes={{ root: classes.NotificationBar }}>
          <TranslateButton  />
          <Box pl={2} pr={2}>
            <Typography variant="body1" classes={{ root: classes.tagline }}>
              Play Hard - Play Fair - Have Fun
            </Typography>
          </Box>
          <Box
            pl={2} pr={2}
            className={css.MessageBox}
            flexGrow={1}
          >
            <Button component={RouterLink} to="/Football" p={0} classes={{ root: classes.NotificationButton }}>
              Football registration ends this Week!
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }

}

export default withStyles(styles)(NotificationBar);
