import React, { Component } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';



const styles = (theme) => createStyles({
  root: {
    height: "100%",
    width: "100%",
  },
});


class GMap extends Component {

  render() {
    const { src, frameBorder, allowFullScreen, classes } = this.props;

    return(
      <Box classes={{ root: classes.root }}>
        <iframe
          title="src"
          src={ src }
          frameBorder={ frameBorder }
          allowFullScreen={ allowFullScreen }
          width="100%"
          height="100%"
        />
      </Box>
    )
  }
}

export default withStyles(styles)(GMap);
