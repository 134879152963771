import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles, createStyles } from '@material-ui/core/styles';
import CopyrightIcon from '@material-ui/icons/Copyright';

import Name from '../../ContactInfo/Name';

const styles = (theme) => createStyles({
  root: {
  },
  leftText: {
    textAlign: "end",
    [theme.breakpoints.down('sm')]: {
      textAlign: "center",
    },
  },
  rightText: {
    textAlign: "start",
    [theme.breakpoints.down('sm')]: {
      textAlign: "center",
    },
  },
  leftGrid: {
    alignContent: "end",
    [theme.breakpoints.down('md')]: {
      textAlign: "center",
    },
  },
  rightGrid: {
    alignContent: "start",
    [theme.breakpoints.down('sm')]: {
      textAlign: "center",
    },
  },
  button: {
    padding: 0,
  },
});

function Copyright(props) {
  const year = new Date().getFullYear();
  const { name } = props;

  return (
    <Box p={1}>
      <CopyrightIcon fontSize="inherit" />
      { year }{ "  " }
      { name }{ "  " }
      { " All Rights Reserved."}
   </Box>
  )
};

class SiteFooter extends Component {

  render() {
    const { classes } = this.props;

    return(
      <Box m={0} p={0} classes={{ root: classes.root }}>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} md={6} classes={{ root: classes.leftGrid }} alignItems="center">
            <Typography variant="body2" classes={{ root: classes.leftText }}>
              <Copyright name={ <Name legal /> }/>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} classes={{ root: classes.rightGrid }}>
            <Button
              href="https://www.abccopyco.com"
              classes={{ root: classes.button }}
              color="primary"
            >
              <Typography variant="body2" classes={{ root: classes.rightText }}>
                Designed by ABC Copy Co
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>
    )
  }
}


export default withStyles(styles)(SiteFooter);
