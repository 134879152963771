import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import { withStyles, createStyles } from '@material-ui/core/styles';


import css from './VideoBackground.module.css';

const styles = (theme) => createStyles({
  root: {
    position: "relative",
    overflow: "hidden",
  },
  video: {
      position: "absolute",
      height: "100%",
      width: "177.77777778vh",
      minWidth: "100%",
      minHeight: "56.25vw",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
  },
});


class VideoBackground extends Component {
  render() {
    const { source, classes } = this.props;

    return (
      <Box className={css.root} classes={{ root: classes.root }}>
        <video autoPlay="autoplay" loop="loop" muted className={ css.Video } classes={{ root: classes.root }}>
            <source src={ source } type="video/mp4" />
            Your browser does not support the video tag. We will show an image slideshow!
        </video>
      </Box>
    );
  }
}

export default withStyles(styles)(VideoBackground);
