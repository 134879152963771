import React, { Component } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles';
//import Image from 'material-ui-image';

const defaultProps = {
  alpha: 0.5,
}

const styles = (theme) => createStyles({
  imgOverlay: {
    position: "relative",
    display: "inline-block",
    height: (props) => props.height,
    width: (props) => props.width,
    '&::after': {
      content: '" "',
      position: "absolute",
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
      display: "inline-block",
      background: (props) =>
        props.dark
          ? fade( "#000", props.alpha ? props.alpha : defaultProps.alpha )
          : '',
    },
  },
  img: {
  }
})


class Image extends Component {
  static defaultProps = {
  }

  colorBackground( filepath, color, alpha = 0.5 ) {
    const colorStr = fade( color, alpha );
    return "linear-gradient( " + colorStr + ", " + colorStr + " ), url(" + filepath + ")";
  }

  darkBackground( filepath, alpha = 0.5 ) {
    return this.colorBackground( filepath, "white", alpha );
  }

  lightBackground( filepath, alpha = 0.5 ) {
    return this.colorBackground( filepath, "black", alpha );
  }

  render() {
    const { src, alt, dark, light, alpha, color, classes, ...props } = this.props;

    return (
      <div className={ classes.imgOverlay } { ...props }>
        <img
          src={ src }
          alt={ alt ? alt : src }
          className={ classes.img }
          { ...props }
        />
      </div>
    );
  }
}


export default withStyles(styles)(Image);
