import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { createStyles, withStyles } from '@material-ui/core/styles';


/* SETTINGS */
//const timeoutLength = 300;

const styles = (theme) => createStyles({
  root: {

  },
  menuButtonLabel: {
    fontSize: '1rem',
    fontWeight: 600,
    textAlign: 'left',
    alignItems: 'left',
    justify: 'left',
    wordSpacing: '120px',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
    '&:focus': {
      color: theme.palette.secondary.main,
    },
  },
  menuButton: {
    justify: 'start',
    width: '120px',
    '&:hover': {
      backgroundColor: "rgba(0,0,0,0)",
    },
    '&:focus': {
      outline: "none",
    },
  },
});





class NavMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: null,
      loading: false,
      open: false,
      setOpen: false,
      anchorEl: null,
    };

  }

  propTypes = {
    buttons: PropTypes.object.isRequired,
  };


  render() {
    const { data, ...props } = this.props;
    //const { open, setOpen, anchorEl } = this.state;

    if( data ) {
      return (
        <Box { ...props } display="flex" alignItems="center">
          <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
            {
              /*data.map(( button ) =>
                <NavMenuButton
                  key={ button.linkRef.id }
                  menu={ button }
                  ref={ button.linkRef.id}
                  aria-controls={ open ? 'menu-list-grow' : undefined }
                  aria-haspopup="true"
                  classes={ button }
                />
              )*/
            }
          </ButtonGroup>
        </Box>
      );
    }

    return null;
  }
}

NavMenu.propTypes = {
  buttons: PropTypes.object.isRequired,
};

export default withStyles(styles)(NavMenu);
