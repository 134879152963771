/*"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _createSvgIcon = _interopRequireDefault(require(".@material-ui/icons/utils/createSvgIcon"));

var _default = (0, _createSvgIcon.default)(_react.default.createElement("path", {
  d: "M3 5v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2H5c-1.11 0-2 .9-2 2zm12 4c0 1.66-1.34 3-3 3s-3-1.34-3-3 1.34-3 3-3 3 1.34 3 3zm-9 8c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1H6v-1z"
}), 'AccountBox');

exports.default = _default;*/


import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from '@material-ui/core/SvgIcon';

let SportsLacrosseIcon = (props) => (
  <SvgIcon {...props}>
    <g>
    	<path d="M10.6,12.6c-1.1,0-1-0.1-1.1,0.9c0,1,0,1,0.9,1c1.1,0,1,0.1,1-0.9C11.4,12.6,11.4,12.5,10.6,12.6z"/>
    	<path d="M13.6,6.2c-0.9,0-1,0-0.9,0.9c0,1.1-0.1,1,1,1c0.9,0,0.9,0,0.9-0.9C14.5,6.1,14.6,6.3,13.6,6.2z"/>
    	<path d="M16.7,5c1.1,0,1,0.1,1-0.9c0-1,0-1-0.9-1c-1.1,0-1-0.1-1,0.9C15.9,5,15.9,5,16.7,5z"/>
    	<path d="M13.5,5c1.1,0,1,0.1,1-0.9c0-0.9,0-1-0.8-0.9c-1.1,0-1-0.1-1,0.9C12.6,5,12.6,5,13.5,5z"/>
    	<path d="M13.7,9.5c-1,0-1,0-1,0.8c0,1.1-0.1,1,0.9,1c1.1,0,1,0.1,1-0.9C14.5,9.5,14.5,9.5,13.7,9.5z"/>
    	<path d="M16.9,9.5c-1,0-1,0-1,0.9c0,1,0,1,0.9,1c1,0,1,0,1-0.9C17.7,9.5,17.7,9.5,16.9,9.5z"/>
    	<path d="M16.8,6.2c-0.9,0-0.9,0-0.9,0.9c0,1.1-0.1,1,0.9,1c0.9,0,0.9,0,0.9-0.9C17.7,6.1,17.8,6.3,16.8,6.2z"/>
    	<path d="M23.7,7.5c-1.1-3.2-3.2-5.5-6.3-6.9c-1.1-0.5-2.3-0.8-3.6-0.5c-1.5,0.3-2.9,2.2-3,4c-0.1,2.3-0.8,4.1-2.5,5.8
    		c-1.6,1.5-2.3,3.4-1.6,5.7c-1.8,1.9-3.7,3.7-5.5,5.6C0.7,21.5,0,22,0,22.4c0,0.4,0.8,0.8,1.2,1.3c0.4,0.5,0.6,0.4,1,0
    		c2.1-2.1,4.2-4.2,6.4-6.4c1.6,0.5,3,0.2,4.4-0.6c0.7-0.4,1.3-1,1.9-1.5c1-1,2.1-1.7,3.5-1.8c0.6-0.1,1.1-0.1,1.7-0.2
    		C22.9,12.5,24.8,10.7,23.7,7.5z M22.7,9.5c-0.7,0-0.9,0.3-0.5,1.2c-1,1-2.3,1.2-3.6,1.3c-1.5,0.1-2.9,0.7-4,1.6c0-0.2,0-0.3,0-0.5
    		c0.1-0.5-0.2-0.6-0.6-0.6c-1.5,0.1-1.2-0.2-1.2,1.3c0,0,0,0.1,0,0.1c0,0.3,0.1,0.5,0.4,0.5c0.2,0,0.4,0,0.5,0c-0.9,1-2,1.5-3.3,1
    		c-1.8-0.6-3-3.5-0.8-5.1c0.1,1,0.1,1,1,1c0.2,0,0.4,0,0.5,0c0.2,0,0.3-0.1,0.3-0.3c0-0.5,0.2-1.1-0.1-1.5c-0.2-0.3-0.7-0.1-1-0.1
    		c1.2-1.4,1.5-3,1.6-4.8c0.1-1,0.4-2,1.2-2.7c0.2,0,0.5,0,0.7,0c0.5,0.1,0.7-0.2,0.6-0.7c0.4,0,0.8,0,1.2,0.1
    		C15.9,2,16.2,2.1,17,1.8c0.8,0.3,1.5,0.7,2.1,1.3C18.8,3.7,19,4.2,19,4.8C19,4.9,19.1,5,19.2,5c0.6,0,1.1,0.2,1.7-0.1
    		C21.4,5.4,21.8,6,22,6.8c0,0.3,0,0.6,0,0.9c0,0.4,0.1,0.6,0.5,0.6C22.8,8.6,22.7,9.1,22.7,9.5z"/>
    	<path d="M19.9,9.5c-1,0-1,0-1,0.9c0,1.1-0.1,1,0.9,1c1,0,1,0,1-0.9C20.8,9.4,21,9.5,19.9,9.5z"/>
    	<path d="M19.8,8.1c1,0,1,0,1-0.9c0-1,0-1-0.8-1c-1.1,0-1-0.1-1.1,0.9C18.9,8.1,18.9,8.1,19.8,8.1z"/>
    </g>
    <circle cx="15.9" cy="21" r="3"/>
  </SvgIcon>
);
SportsLacrosseIcon = pure(SportsLacrosseIcon);
SportsLacrosseIcon.displayName = 'SportsLacrosseIcon';
SportsLacrosseIcon.muiName = 'SvgIcon';

export default SportsLacrosseIcon;
