import React, { Component } from 'react';
import clsx from  'clsx';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { withStyles, createStyles } from '@material-ui/core/styles';


const styles = (theme) => createStyles({
  root: {
    '&$focused': {
      color: '#000',
    },
  },
  labelOffset: {
    transform: "translate(44px, 20px) scale(1)",
  },
  white: {
    color:'#FFFFFF',
    borderWidth: "1px",
    borderColor: '#FFFFFF',
    '& label.Mui-focused': {
      color: 'gray',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'gray',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'gray',
      },
    },
  },
});



class TextBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shrink: false,
    }

    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  static defaultProps = {
    variant: "outlined",
  }

  onFocus(event) {
    this.setState({ shrink: true });
  }

  onBlur(event) {
    if (event.target.value.length === 0)
      this.setState({ shrink: false });
  }

  render() {
    const { classes, white, startAdornment, endAdornment, ...props } = this.props;
    const { variant } = this.props;
    const { shrink } = this.state;

    const color = white ? classes.white : null;

    const inputProps= {
      classes: {
        root: color,
      },
      startAdornment: startAdornment?
         <InputAdornment position="start">
           { startAdornment }
         </InputAdornment>
         :null,
      endAdornment: endAdornment?
        <InputAdornment position="end">
          { endAdornment }
        </InputAdornment>
        :null,
    };

    const labelRoot = (variant === "outlined") ? clsx(classes.labelOffset, color) : color;
    const inputLabelProps = {
      shrink: shrink,
      classes:{
        root: labelRoot,
      }
    };

    return(
        <TextField
           { ...props }
           onFocus={ this.onFocus }
           onBlur={ this.onBlur }
           classes={{ root: color }}
           InputProps={ inputProps }
           InputLabelProps={ inputLabelProps }
         />
    );
  }
}

export default withStyles(styles)(TextBox);
