import React, { Component } from "react";
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Slider from "react-slick";



import css from './SponsorStream.module.css';

const styles = (Theme) => createStyles({
  root: {
    height: "175px",
  },
  card: {
    width: "300px",
    height: "175px",
  },
  button: {
    width: "100%",
    height: "100%",
  },
  label: {
    width: "100%",
    height: "100%",
  },
  img: {
    objectFit: "contain",
    width: "100%",
    height: "175px",
  }
});


class SponsorStream extends Component {

  render() {
    const { sponsors, classes, ...props } = this.props;

    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
      cssEase: "linear"
    };

    return (
      <Box p={4} { ...props }>
        <Slider {...settings} mb={4} classes={{ root: classes.root }}>
          { sponsors.map( (item, key) => (
            <Box classes={{ root: classes.card }} p={4}>
              <Button
                classes={{ root: classes.button, label: classes.label }}
                href={ item.website }
              >
                <img
                  className={ css.sponsorLogo }
                  classes={{ root: classes.img }}
                  src={ item.img }
                  alt={ item.name }
                />
              </Button>
            </Box>
            )
          )}
        </Slider>
      </Box>
    );
  }
}

export default withStyles(styles)(SponsorStream);
