import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { createStyles, withStyles } from '@material-ui/core/styles';

import DesktopMenu from './DesktopMenu';
import MobileMenu from './MobileMenu';


const styles = (theme) => createStyles({
  root: {
  }
});


class NavigationMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: null,
      loading: false,
      open: false,
      setOpen: false,
      anchorEl: null,
    };
  }

  static propTypes = {
    menuItems: PropTypes.object.isRequired,
  };

  render() {
    const { menuItems, mobile, classes, ...props } = this.props;

    const menu = mobile
      ? <MobileMenu menuItems={ menuItems?.mobile } classes={{ root: classes.mobile }} />
      : <DesktopMenu menuItems={ menuItems?.desktop } classes={{ root: classes.desktop }} />;

    return (
      <Box { ...props } alignItems="center">
        { menu }
      </Box>
    );
  }
}

export default withStyles(styles)(NavigationMenu);
