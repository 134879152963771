import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { withStyles, createStyles } from '@material-ui/core/styles';

import SportsBaseballIcon from '@material-ui/icons/SportsBaseball';
import SportsBaseballOutlinedIcon from '@material-ui/icons/SportsBaseball';
import SportsBasketballIcon from '@material-ui/icons/SportsBasketball';
import SportsFootballIcon from '@material-ui/icons/SportsFootball';
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import SportsVolleyballIcon from '@material-ui/icons/SportsVolleyball';
import SportsLacrosseIcon from './SportsLacrosseIcon';

import IconCard from '../';


const styles = ({ palette }: Theme) => createStyles({
  root: {

  },
});



class SportsCards extends Component {
  render() {
    return(
      <Box pt={4} pb={4}>
        <Grid align="center" justify="center">
          <Grid container xs={10} align="center" justify="center">
            <Grid item xs={12} md={6} lg={3} pt={0}>
              <SportIconCard
                icon="baseball"
                title="Baseball"
                description={ <p>4U – 14U<br/>
                              Fall & Spring<br/>
                              Rec & Competitive</p> }
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3} >
              <SportIconCard
                icon="basketball"
                title="Basketball"
                description={ <p>Boys & Girls<br/>
                              1st – 12th Grade<br/>
                              November - February</p> }
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3} >
              <SportIconCard
                icon="football"
                title="Flag Football"
                description={ <p>1st – 7th Grade<br/>
                              Fall & Spring<br/>
                              NFL Flag League</p> }
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3} >
              <SportIconCard
                icon="lacrosse"
                title="Lacrosse"
                description={ <p>Girls 1st – 8th Grade<br/>
                              Fall Clinics<br/>
                              Spring Season</p> }
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3} >
              <SportIconCard
                icon="soccer"
                title="Soccer"
                description={ <p>Boys & Girls<br/>
                              U4 – U19<br/>
                              Fall & Spring<br/>
                              Rec, Academy & Select</p> }
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3} >
              <SportIconCard
                icon="softball"
                title="Softball"
                description={ <p>Girls 6U - 16U<br/>
                              Fall & Spring<br/>
                              </p> }
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3} >
              <SportIconCard
                icon="volleyball"
                title="Volleyball"
                description={ <p>3rd – 8th Grade<br/>
                              Fall & Spring<br/>
                              Indoor Gym</p> }
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

class SportIconCard extends Component {
  sportIcon(sport) {
    switch( sport.toLowerCase() ) {
      case 'baseball':
        return <SportsBaseballOutlinedIcon style={{ fontSize: 80 }} align="center" color="primary"/>;
      case 'basketball':
        return <SportsBasketballIcon style={{ fontSize: 80 }} align="center" color="primary"/>;
      case 'football':
        return <SportsFootballIcon style={{ fontSize: 80 }} align="center" color="primary"/>;
      case 'lacrosse':
        return <SportsLacrosseIcon style={{ fontSize: 80 }} align="center" color="primary"/>;
      case 'soccer':
        return <SportsSoccerIcon style={{ fontSize: 80 }} align="center" color="primary"/>;
      case 'softball':
        return <SportsBaseballIcon style={{ fontSize: 80 }} align="center" color="primary"/>;
      case 'volleyball':
        return <SportsVolleyballIcon style={{ fontSize: 80 }} align="center" color="primary"/>;
      default:
        return null;
    }
  }

  render() {
    const { icon, title, description } = this.props;
    const iconImage = this.sportIcon(icon);

    return(
      <IconCard
        title={ title }
        description={ description }
      >
        { iconImage }
      </IconCard>
    );
  }
}

export default withStyles(styles)(SportsCards);
