import React from 'react';
import PropTypes from 'prop-types';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { createStyles, withStyles } from '@material-ui/core/styles';

import MenuButton from './MenuButton';



const styles = (theme) => createStyles({
  root: {

  },
  button: {
    justify: 'start',
    width: '120px',
    '&:hover': {
      backgroundColor: "rgba(0,0,0,0)",
    },
    '&:focus': {
      outline: "none",
    },
  },
  label: {
    width: '8rem',
    fontSize: '1rem',
    fontWeight: 600,
    textAlign: 'left',
    wordSpacing: '999px',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
    '&:focus': {
      color: theme.palette.secondary.main,
    },
  },
});





class DesktopMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: null,
      loading: false,
      open: false,
      setOpen: false,
      anchorEl: null,
    };

  }

  static propTypes = {
    menuItems: PropTypes.object.isRequired,
  };

  render() {
    const { menuItems, classes, ...props } = this.props;

    const buttons = menuItems?.buttons;

    return (
      <ButtonGroup
        { ...props }
        classes={{ root: classes.root }}
        variant="text"
        color="primary"
        aria-label="text primary button group"
      >
        {
          buttons?.map(( item, key ) =>
            <MenuButton
              key={ key }
              { ...item }
              classes={{ root: classes.button, label: classes.label }}
            />
          )
        }
      </ButtonGroup>
    );
  }
}



export default withStyles(styles)(DesktopMenu);
