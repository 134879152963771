import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { withStyles, createStyles } from '@material-ui/core/styles';

import Hero from '../../components/Hero';
import Team from '../../components/Team';

const SoccerBoard = {
  groups: [
    {
      name: "Board Members",
      description: "Oversight of Soccer Organization",
      members: [
        {
          name: "Kyle Patterson",
          email: "Kyle",
          phone: "123456789",
          message: "123456789",
          title: 'President',
          city: "Austin",
          soccer: true,
        },
        {
          name: "Todd Brooker",
          email: "Todd",
          phone: "123456789",
          message: "123456789",
          title: 'Past President',
          city: "Austin",
          soccer: true,
        },
        {
          name: "Josh Brown",
          email: "Josh",
          phone: "123456789",
          message: "123456789",
          title: 'VP Operations',
          city: "Austin",
          soccer: true,
          initials: true,
        },
        {
          name: "Mark Sapp",
          email: "mark@sappster.com",
          phone: "123456789",
          message: "123456789",
          gravatar: "mark@sappster.com",
          github: "sappster",
          title: 'Director of Communications',
          city: "Austin",
          soccer: true,
          football: true,
        },
        {
          name: "David Chavez",
          email: "David",
          phone: "123456789",
          message: "123456789",
          title: 'Registrar',
          city: "Austin",
          soccer: true,
          initials: true,
        },
        {
          name: "Chris Johnson",
          email: "Chris",
          phone: "123456789",
          message: "123456789",
          title: 'Rec Coordinator',
          city: "Austin",
          soccer: true,
          initials: true,
        },
        {
          name: "Ray Kasel",
          email: "Ray",
          phone: "123456789",
          message: "123456789",
          title: 'Director of Coaches',
          city: "Austin",
          soccer: true,
          initials: true,
        },
      ]
    },
    {
      name: "Division Commisioners",
      description: "",
      members: [
        {
          name: "- - - - -",
          email: "",
          phone: "",
          message: "",
          title: 'U4 Coed Comissioner',
          city: " ",
          soccer: false,
        },
        {
          name: "- - - - -",
          email: "",
          phone: "",
          message: "",
          title: 'U5 Coed Comissioner',
          city: " ",
          soccer: false,
        },
        {
          name: "- - - - -",
          email: "",
          phone: "",
          message: "",
          title: 'U6 Boys Comissioner',
          city: " ",
          soccer: false,
        },
        {
          name: "- - - - -",
          email: "",
          phone: "",
          message: "",
          title: 'U6 Girls Comissioner',
          city: " ",
          soccer: false,
        },
        {
          name: "- - - - -",
          email: "",
          phone: "",
          message: "",
          title: 'U7 Boys Comissioner',
          city: " ",
          soccer: false,
        },
        {
          name: "- - - - -",
          email: "",
          phone: "",
          message: "",
          title: 'U7 Girls Comissioner',
          city: " ",
          soccer: false,
        },
        {
          name: "- - - - -",
          email: "",
          phone: "",
          message: "",
          title: 'U8 Boys Comissioner',
          city: " ",
          soccer: false,
        },
        {
          name: "- - - - -",
          email: "",
          phone: "",
          message: "",
          title: 'U8 Girls Comissioner',
          city: " ",
          soccer: false,
        },
        {
          name: "- - - - -",
          email: "",
          phone: "",
          message: "",
          title: 'U9 Boys Comissioner',
          city: " ",
          soccer: false,
        },
        {
          name: "- - - - -",
          email: "",
          phone: "",
          message: "",
          title: 'U9 Girls Comissioner',
          city: " ",
          soccer: false,
        },
        {
          name: "- - - - -",
          email: "",
          phone: "",
          message: "",
          title: 'U10 Boys Comissioner',
          city: " ",
          soccer: false,
        },
        {
          name: "- - - - -",
          email: "",
          phone: "",
          message: "",
          title: 'U10 Girls Comissioner',
          city: " ",
          soccer: false,
        },
        {
          name: "Chris Johnson",
          email: "chris",
          phone: "",
          message: "",
          title: 'Academy Director',
          city: " ",
          soccer: false,
        },
        {
          name: "Scott Brenneman",
          email: "Scott",
          phone: "",
          message: "",
          title: 'DIII U11-U19 Boys Commissioner',
          city: " ",
          soccer: false,
        },
        {
          name: "Scott Brenneman",
          email: "Scott",
          phone: "",
          message: "",
          title: 'DII U11-U19 Boys Commissioner',
          city: " ",
          soccer: false,
        },
        {
          name: "Erin & Kyle Patterson",
          email: "Erin",
          phone: "",
          message: "",
          title: 'DIII U11-U19 Girls Commissioner',
          city: " ",
          soccer: false,
        },
        {
          name: "David Chavez",
          email: "David",
          phone: "",
          message: "",
          title: 'DI/S2 Commissioner',
          city: " ",
          soccer: false,
        },
        {
          name: "- - - - - -",
          email: "",
          phone: "",
          message: "",
          title: 'Futsal Commissioner',
          city: " ",
          soccer: false,
        },
      ]
    },
    {
      name: "Board At Large",
      description: "",
      members: [
        {
          name: "Josh Brown",
          email: "josh",
          phone: "",
          message: "",
          title: 'Tournament Director',
          city: " ",
          soccer: false,
        },
        {
          name: "- - - - -",
          email: "",
          phone: "",
          message: "",
          title: 'Acting Volunteer Coordinator & Picture/Trophy Coordinator',
          city: " ",
          soccer: false,
        },
        {
          name: "Rob Cowart",
          email: "",
          phone: "",
          message: "",
          title: 'Game Scheduler',
          city: " ",
          soccer: false,
        },
        {
          name: "Michelle Pederson",
          email: "Michelle",
          phone: "",
          message: "",
          title: 'Referee Assignor',
          city: " ",
          soccer: false,
        },
        {
          name: "Rob Cowart",
          email: "",
          phone: "",
          message: "",
          title: 'Optimist Clu Representative',
          city: " ",
          soccer: false,
        },
        {
          name: "Chris Johnson",
          email: "Chris",
          phone: "",
          message: "",
          title: 'Practice Field Coordinator',
          city: " ",
          soccer: false,
        },
        {
          name: "David Chavez",
          email: "David",
          phone: "",
          message: "",
          title: 'Referee Development',
          city: " ",
          soccer: false,
        },
        {
          name: "Eric Sanchez",
          email: "Eric",
          phone: "",
          message: "",
          title: 'U8 Girls Comissioner',
          city: " ",
          soccer: false,
        },

      ]
    },
  ]
};


const styles = (theme) => createStyles({
  root: {
    paddingBottom: "10rem",
  }
});



function BoardPage(props) {
  const { classes } = props;

  return (
    <Grid container justify="center" classes={{ root: classes.root }}>
      <Hero
        source= "/includes/images/VID_20191104_185523.mp4"
        title="T&C Soccer Board"
      />
      <Grid item sm={12} md={10}>
        <Team { ...SoccerBoard } {...props} />
      </Grid>
    </Grid>
  );
}


export default withStyles(styles)(BoardPage);
