import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
  },
});

const headers2 = [
  {
    property: "division",
    label: "Division",
  },
  {
    property: "birthYear",
    label: "Birth Year",
  },
  {
    property: "teamSize",
    label: "Team Size",
  },
  {
    property: "ballSize",
    label: "Ball Size",
  },
  {
    property: "Registration",
    label: "Registration",
  },
  {
    property: "registrationLink",
    label: "",
  }
];

function renderRow( row, key, headers ) {
  console.log(headers);
  return (
    <TableRow key={ key }>
      { headers2?.map( (header, hKey) => (
        (hKey === 0) ? <TableCell component="th" scope="row">
            { row["division"] }
          </TableCell> :
        <TableCell align="right">{ row[header.property] }</TableCell>
      ))}
    </TableRow>
  );
}

export default function SimpleTable(props) {
  const { rows, headers } = props
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            { headers?.map( (header, key) => (
              (key === 0) ? <TableCell >{ header.label }</TableCell> :
              <TableCell align="right">{ header.label }</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          { rows?.map(renderRow.bind( null, 1, headers ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
