import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Image from '../../../Image';


const styles = (theme) => createStyles({
  root: {
    height: '100%',
  },
  button: {
    whiteSpace: 'nowrap',
  },
  panel: {
    height: '100%',
    alignItems: "stretch",
    display: "flex",
  },
  imagePanel: {
    height: '100%',
    padding: '0.5rem',

  },
  image: {
    //height: '100%',
    //position: 'relative',
    //padding: '0rem'
  },
  container: {
    display: 'flex',
  },
  paper: {
    margin: theme.spacing(1),
  },
  svg: {
    width: 100,
    height: 100,
  },
  polygon: {
    fill: theme.palette.common.white,
    stroke: theme.palette.divider,
    strokeWidth: 1,
  },
});




class MenuPanel extends Component {
  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    const { menu, image, classes, ...props } = this.props;

    return (
      <Box
        role="menupanel"
        { ...props }
        classes={{ root: classes.root }}
      >
        <Box classes={{ root: classes.panel }}>
          <Box p={1}>
            { menu &&
              <ButtonGroup
                orientation="vertical"
                color="primary"
                aria-label="vertical contained primary button group"
                variant="text"
              >
                { menu && menu.items.map(( item, key ) =>
                    <Button
                      classes={{ root: classes.button }}
                      color="primary"
                      style={{ fontSize: '1.5rem' }}
                      key={ key }

                    >
                      { item.label }
                    </Button>
                  )}
                }
              </ButtonGroup>
            }
          </Box>
          {
            image &&
            <Box classes={{ root: classes.imagePanel }} >
              <Image
                src={ image }
                classes={{ root: classes.image }}
                height="300px"
                width="300px"
              />
            </Box>
          }
        </Box>
      </Box>
    );
  }
}

export default withStyles(styles)(MenuPanel);
