import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import { createStyles, withStyles } from '@material-ui/core/styles';


const styles = (theme) => createStyles({
  root: {
    position: "relative",
    width: "100%",
    //padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      height: "400px",
    },
    [theme.breakpoints.up('md')]: {
      height: "450px",
    },
    [theme.breakpoints.up('lg')]: {
      height: "600px",
    },
  },
  link: {
    position: "absolute",
    height: "100%",
    width: "100%",
    top: 0,
    left: 0,
    display: "inline-block",
    zIndex: 5,
  },
  frame: {
    height: "100%",
    width: "100%",
    border: "none",
  }
});

const ThorGuard = (propsIn) =>  {
  const { account, mobile, classes, ...props } = propsIn;

  return (
    <Paper { ...props } classes={{ root: classes.root }}>
      <iframe
        src={ `http://${account}.thormobile7.net/${ mobile ? 'mobile/' : ''}` }
        className={ classes.frame }
        scrolling="no"
      />
      <Link classes={{ root: classes.link }} href={ `http://${account}.thormobile7.net/${ mobile ? 'mobile/' : ''}` }/>
    </Paper>
  );
}

export default withStyles(styles)(ThorGuard);
