import React, { Component } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Backdrop from '@material-ui/core/Backdrop';
import Modal from '@material-ui/core/Modal';

import Image from '../Image';
import MapPanel from './MapPanel';

import css from './Map.module.css';

const styles = (theme) => createStyles({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  fieldMapButton: {
    width: "100%",
  },
  text: {
    variant: "h1"
  },
  fullHeight: {
    height: "100%",
  },
  instructions: {
    margin: "1rem",
    fontSize: "1.5rem",
    fontWeight: 400,
    color: theme.palette.primary.main,
  },
  instructionsPaper: {
    margin: "1rem",
    BackgroundColor: theme.palette.primary.main,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  modalContainer: {
    height: "100%",
  },
  modalPaper: {
    padding: '5px',
  },
  modalImage: {
    Width: '95vw',
    Height: '95vh',
  }
});





function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}


class Map extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newValue: 0,
      open: false,
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleFieldMapClose = this.handleFieldMapClose.bind(this);
    this.handleFieldMapToggle = this.handleFieldMapToggle.bind(this);
  }

  handleChange = (event, newValue) => {
    this.setState({ newValue: newValue });
  };

  handleFieldMapClose() {
    this.setState({ open: false });
  };

  handleFieldMapToggle() {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { classes } = this.props;
    const { newValue, open } = this.state;

    return(
      <Box>
        <Box mb={4}>
          <Typography classes={{ root: classes.text }}>
            The T&C Sports Campus has three parking lots, each with its own street address.  <br />
            Although you can walk to any of our fields from any lot, certain parking lots are more convenient if you know which field you will be going to. <br />
            See the map to determine which parking lot is most convenient to you.
          </Typography>
          <Typography variant="subtitle1" classes={{ root: classes.instructions }}>
            Select an entrance or field below to view on the Map
          </Typography>
        </Box>

          <Grid container spacing={3} >
            <Grid item xs={12} md={5}>
              <Paper className={css.paper}>
                <Button classes={{ root: classes.fieldMapButton }} onClick={ this.handleFieldMapToggle }>
                  <img src="./includes/images/fieldmap.svg" alt="" width="100%" />
                </Button>
              </Paper>
            </Grid>
            <Modal className={classes.backdrop} open={open} onClick={ this.handleFieldMapClose }>
              <Box display="flex" className={classes.modalContainer} justify="center">
                <Paper classes={{ root: classes.modalPaper }}>
                  <Image src="/includes/images/fieldmap.svg" alt="Field Map" classes={{ root: classes.modalImage }}/>
                </Paper>
              </Box>
            </Modal>

            <Grid item xs={12} md={7} alignItems="stretch">
              <Paper className={css.paper} classes={{ root: classes.fullHeight }}>
                <Grid container classes={{ root: classes.fullHeight }}>
                  <Grid item xs={4}>
                    <Tabs
                      orientation="vertical"
                      value={ newValue }
                      onChange={ this.handleChange }
                      aria-label="Directions"
                      textColor="primary"
                    >
                      <Tab label="Meadowheath Entrance" value={0} {...a11yProps(0)} />
                      <Tab label="Briarwick Entrance" value={1} {...a11yProps(1)} />
                      <Tab label="Morris Entrance" value={2} {...a11yProps(2)} />
                      <Divider variant="middle" />
                      <Tab label="Miracle League" value={3} {...a11yProps(3)} />
                      <Tab label="T&C Pavilion" value={4} {...a11yProps(4)} />
                      <Tab label="T&C Office" value={5} {...a11yProps(5)} />
                      <Tab label="Field 1, 2, 7-10" value={6} {...a11yProps(6)} />
                      <Tab label="Field 3 - 6, 31 - 36" value={7} {...a11yProps(7)} />
                      <Tab label="Field 11 - 15, 20 - 28" value={8} {...a11yProps(8)} />
                    </Tabs>
                  </Grid>

                  <Grid item xs={8}>
                    <MapPanel
                      value={newValue}
                      index={0}
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3439.0408050487454!2d-97.7730210844617!3d30.463282305618776!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8644cd43e654d3af%3A0x2343761678802649!2s9100%20Meadowheath%20Dr%2C%20Austin%2C%20TX%2078729!5e0!3m2!1sen!2sus!4v1583862088406!5m2!1sen!2sus"
                    >
                      <Typography variant="h2">
                        9100 Meadowheath Drive
                      </Typography>
                      <Typography variant="body1">
                        T&C Sports Office (Blue Building) <br />
                        Fields 1,2,7-10
                      </Typography>
                    </MapPanel>

                    <MapPanel
                      value={newValue}
                      index={1}
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3438.9633768644308!2d-97.7732585844616!3d30.465475405513832!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8644cd4451b61803%3A0xe40de288e264842e!2s13450%20Briarwick%20Dr%2C%20Austin%2C%20TX%2078729!5e0!3m2!1sen!2sus!4v1583862269080!5m2!1sen!2sus"
                    >
                      <Typography variant="h2">
                        13450 Briarwick
                      </Typography>
                      <Typography variant="body1">
                        T&C Pavilion <br />
                        Fields 11-15 & 20-28
                      </Typography>
                    </MapPanel>

                    <MapPanel
                      value={newValue}
                      index={2}
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3439.2351423859463!2d-97.76931368446176!3d30.45777720588194!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8644cd412fa4d599%3A0x35bbe73fc1fa967!2s13300%20Morris%20Rd%2C%20Austin%2C%20TX%2078729!5e0!3m2!1sen!2sus!4v1583862339145!5m2!1sen!2sus"
                    >
                      <Typography variant="h2">
                        13300 Morris Road
                      </Typography>
                      <Typography variant="body1">
                        Miracle League Baseball Field <br />
                        Fields 3-6, 31-36
                      </Typography>
                    </MapPanel>

                    <MapPanel
                      value={newValue}
                      index={3}
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3439.1560835990153!2d-97.77086393446174!3d30.460016855775056!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8644cd40d30b66d1%3A0xca18f1b1915b068c!2sMiracle%20League%2C%20Morris%20Rd%2C%20Austin%2C%20TX%2078729!5e0!3m2!1sen!2sus!4v1589926150969!5m2!1sen!2sus"
                    >
                      <Typography component="p" variant="h2">
                        Miracle League
                      </Typography>
                    </MapPanel>

                    <MapPanel
                      value={newValue}
                      index={4}
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3439.0408050487454!2d-97.7730210844617!3d30.463282305618776!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8644cd43e654d3af%3A0x2343761678802649!2s9100%20Meadowheath%20Dr%2C%20Austin%2C%20TX%2078729!5e0!3m2!1sen!2sus!4v1583862088406!5m2!1sen!2sus"
                    >
                      <Typography component="p" variant="h2">
                        T&C Pavilion
                      </Typography>
                    </MapPanel>

                    <MapPanel
                      value={newValue}
                      index={5}
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3439.0408050487454!2d-97.7730210844617!3d30.463282305618776!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8644cd43e654d3af%3A0x2343761678802649!2s9100%20Meadowheath%20Dr%2C%20Austin%2C%20TX%2078729!5e0!3m2!1sen!2sus!4v1583862088406!5m2!1sen!2sus"
                    >
                      <Typography component="p" variant="h2">
                        T&C Office
                      </Typography>
                    </MapPanel>

                    <MapPanel
                      value={newValue}
                      index={6}
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3439.0408050487454!2d-97.7730210844617!3d30.463282305618776!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8644cd43e654d3af%3A0x2343761678802649!2s9100%20Meadowheath%20Dr%2C%20Austin%2C%20TX%2078729!5e0!3m2!1sen!2sus!4v1583862088406!5m2!1sen!2sus"
                    >
                      <Typography component="p" variant="h2">
                        Fields 1, 2, 7 - 10
                      </Typography>
                    </MapPanel>

                    <MapPanel
                      value={newValue}
                      index={7}
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3439.0408050487454!2d-97.7730210844617!3d30.463282305618776!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8644cd43e654d3af%3A0x2343761678802649!2s9100%20Meadowheath%20Dr%2C%20Austin%2C%20TX%2078729!5e0!3m2!1sen!2sus!4v1583862088406!5m2!1sen!2sus"
                    >
                      <Typography component="p" variant="h2">
                        Field 3 - 6, 31 - 36
                      </Typography>
                    </MapPanel>

                    <MapPanel
                      value={newValue}
                      index={8}
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3439.0408050487454!2d-97.7730210844617!3d30.463282305618776!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8644cd43e654d3af%3A0x2343761678802649!2s9100%20Meadowheath%20Dr%2C%20Austin%2C%20TX%2078729!5e0!3m2!1sen!2sus!4v1583862088406!5m2!1sen!2sus"
                    >
                      <Typography component="p" variant="h2">
                        Field 11 - 15, 20 - 28
                      </Typography>
                    </MapPanel>

                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>
    )
  }
}

export default withStyles(styles)(Map);
