import defaultPalette from '../Colors';

const defaultTypography = {
  button: {
    fontSize: '1.4rem',
    fontFamily: [
      'Open Sans',
      'sans-serif',
    ].join(','),
    fontWeight: 200,
    fontStyle: 'normal',
    margin: 1,
    paddingTop: 0,
    textTransform: 'Capitalize',
    lineHeight: 1.2,
    letterSpacing: '-0.00833em',
  },

  h1: {
    fontSize: '8rem',
    fontFamily: [
      'Open Sans',
      'sans-serif',
    ].join(','),
    color: defaultPalette.primary.main,
    fontWeight: 600,
    fontStyle: 'normal',
    margin: 1,
    paddingTop: 0,
    textTransform: 'Capitalize',
    lineHeight: 1.2,
    letterSpacing: '-0.00833em',
  },

  h2: {
    fontSize: '1.8rem',
    fontFamily: [
      'Open Sans',
      'sans-serif',
    ].join(','),
    color: defaultPalette.primary.main,
    fontWeight: 400,
    fontStyle: 'normal',
    margin: 1,
    paddingTop: 0,
    textTransform: '',
    lineHeight: 1.2,
    letterSpacing: '-0.00833em',
  },

  h3: {
    fontSize: '3rem',
    fontFamily: [
      'Roboto',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
    color: defaultPalette.primary.main,
    fontWeight: 400,
    fontStyle: 'normal',
    margin: 1,
    paddingTop: 0,
    textTransform: 'Capitalize',
    lineHeight: 1.167,
    letterSpacing: '0em',
  },

  h4: {
    fontSize: '3rem',
    fontFamily: [
      'Open Sans',
      'sans-serif',
    ].join(','),
    color: defaultPalette.secondary.main,
    fontWeight: 500,
    fontStyle: 'normal',
    margin: 1,
    paddingTop: 0,
    textTransform: 'Capitalize',
    lineHeight: 1.235,
    letterSpacing: '0.00735em',
  },

  h5: {
    fontSize: '1.5rem',
    fontFamily: [
      'Open Sans',
      'sans-serif',
    ].join(','),
    color: defaultPalette.secondary.main,
    fontWeight: 700,
    fontStyle: 'normal',
    margin: 1,
    paddingTop: 0,
    textTransform: 'Capitalize',
    lineHeight: 1.2,
    letterSpacing: '-0.00833em',
  },

  h6: {
    fontSize: '1.25rem',
    fontFamily: [
      'Roboto',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
    color: defaultPalette.secondary.main,
    fontWeight: 700,
    fontStyle: 'normal',
    margin: 1,
    paddingTop: 0,
    textTransform: 'Capitalize',
    lineHeight: 1.2,
    letterSpacing: '-0.00833em',
  },

  subtitle1: {
    fontSize: '1rem',
    fontFamily: [
      'Roboto',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
    color: defaultPalette.primary.main,
    fontWeight: 100,
    fontStyle: 'normal',
    margin: 1,
    paddingTop: 0,
    textTransform: '',
    lineHeight: 1.75,
    letterSpacing: '0.00938em',
  },

  subtitle2: {
    fontSize: '2rem',
    fontFamily: [
      'Open Sans',
      'sans-serif',
    ].join(','),
    color: defaultPalette.primary.main,
    fontWeight: 100,
    fontStyle: 'normal',
    margin: 1,
    paddingTop: 0,
    textTransform: '',
    lineHeight: 1.75,
    letterSpacing: '0.00938em',
  },

  body1: {
    fontSize: '1.25rem',
    fontFamily: [
      'Open Sans',
      'sans-serif',
    ].join(','),
    color: defaultPalette.primary.main,
    fontWeight: 100,
    fontStyle: 'normal',
    margin: 1,
    paddingTop: 0,
    textTransform: 'none',
    lineHeight: 1.2,
    letterSpacing: '-0.00833em',
  },

  body2: {
    fontSize: '1rem',
    fontFamily: [
      'trade-gothic-next',
      'sans-serif',
    ].join(','),
    color: defaultPalette.primary.main,
    fontWeight: 100,
    fontStyle: 'normal',
    margin: 1,
    paddingTop: 0,
    textTransform: 'none',
    lineHeight: 1.2,
    letterSpacing: '-0.00833em',
  }
}

export default defaultTypography;
