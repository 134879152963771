import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Icon from '../../Icon';
import md5 from 'md5';


const styles = (theme) => ({
  details: {
    margin: theme.spacing(1, 1, 1, 0),
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    margin: theme.spacing(2),
    borderRadius: '50%',
    flexShrink: 0,
    backgroundColor: theme.palette.primary.main,
    fontSize: "xx-large",
  },
  icon: {
    fontSize: 18,
    padding: theme.spacing(1),
  },
  container: {
    margin: theme.spacing(2, 0, 4),
  },
});

class AvatarImage {

  static gravatar( email ) {
    const formattedEmail = ('' + email).trim().toLowerCase();
    const emailHash = md5(formattedEmail, {encoding: "binary"});
    return "https://www.gravatar.com/avatar/" + emailHash;
  }

  static github( github ) {
    return `https://github.com/${ github }.png`;
  }

}


function ContactCard( propsIn ) {
  const { name, initials, email, avatar, github, title, city, twitter, facebook, instagram, classes, ...props } = propsIn;

  const image = avatar ? avatar :
                github ? AvatarImage.github( github ) :
                //email ? AvatarImage.gravatar( email ) :
                "" ;

  const alt = ( image || initials ) ? name : "";

  return (
    <Grid key={name} item { ...props }>
      <Paper variant="outlined">
        <Grid container wrap="nowrap">
          <Grid item>
            <Avatar
              className={classes.avatar}
              src={ image ? image : "/broken-image.jpg" }
              title={ name }
              alt={ alt }
            />
          </Grid>
          <Grid item>
            <div className={classes.details}>
              <Typography component="h3" variant="h6">
                { name }
              </Typography>
              <Typography variant="body2" color="textSecondary">
                { title }
              </Typography>
              <Typography variant="body2" color="textSecondary">
                { city }
              </Typography>
              <Grid container>
                <Icon.Email { ...propsIn }/>
                <Icon.Phone { ...propsIn }/>
                <Icon.Message { ...propsIn }/>

                <Icon.Github { ...propsIn }/>
                <Icon.Twitter { ...propsIn }/>
                <Icon.Facebook { ...propsIn }/>
                <Icon.Instagram { ...propsIn }/>


                <Icon.Baseball { ...propsIn }/>
                <Icon.Basketball { ...propsIn }/>
                <Icon.Football { ...propsIn }/>
                <Icon.Lacrosse { ...propsIn }/>
                <Icon.Soccer { ...propsIn }/>
                <Icon.Softball { ...propsIn }/>
                <Icon.Volleyball { ...propsIn }/>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}

ContactCard.propTypes = {
  classes: PropTypes.object.isRequired,
  description: PropTypes.string.isRequired,
  members: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

export default withStyles(styles)(ContactCard);
