import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Email from '../Email';

import CTAButton from '../../Button/CTA';


const styles = (theme) => createStyles({
  root: {
  },
  emailTextField: {
    color: 'white',
  },
  heading: {
  },
  subTitle: {
    color: 'white',
  },
  submitButton: {
  },
});


class SubscribeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "",
      header: "Subscribe Now",
      subHeader: "OUR FIELD STATUS. YOUR INBOX.",
    };
  }


  render() {
    const { type, header, subHeader } = this.state;
    let { classes } = this.props;

    return(
      <Box
        classes={{ root: classes.root }}
        itemScope itemType={ type }
      >
        <Typography variant="h4" classes={{ root: classes.heading }}>{ header }</Typography>
        <Typography variant="subtitle1" classes={{ root: classes.subTitle }}>{ subHeader }</Typography>

        <form classes={{ root: classes.form }} noValidate>
          <Email white classes={{ root: classes.emailTextField }} />
          <CTAButton classes={{ root: classes.submitButton }}> Sign Me Up </CTAButton>
        </form>
      </Box>
    );
  }

}

export default withStyles(styles)(SubscribeForm);
