import React, { Component } from 'react';
import Box from '@material-ui/core/Box';

import RegisterButton from '../../Button/Register';
import FieldStatusButton from '../../Button/FieldStatus';

//import styles from './QuickLinks.module.css';


class QuickLinks extends Component {

  render() {
    return (

      <Box display="flex" justifyContent="flex-end">
        <FieldStatusButton />
        <RegisterButton />
      </Box>
    );
  }

}

export default QuickLinks;
