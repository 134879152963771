import React, { Component } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { withStyles, createStyles } from '@material-ui/core/styles';


const styles = createStyles({
  root: {

  },
  paper: {
    border: '1px solid #d3d4d5',
  },
  label: {

  },
});


class MenuPopup extends Component {
  StyledMenuItem = withStyles(theme => ({
    root: {
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white,
        },
      },
    },
  }))(MenuItem);

  /*const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }));*/

  render() {
    const { anchorEl, children, onClickAway, ...props } = this.props;
    const open = Boolean(anchorEl);

    return (
      <Popper
        open={ open }
        anchorEl={ anchorEl }
        role={undefined}
        transition
        disablePortal
        aria-controls='menu-list-grow'
        { ...props }
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={ onClickAway }>
                { children }
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    );
  }
}

export default withStyles(styles)(MenuPopup);
