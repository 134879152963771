import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
//import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles, createStyles } from '@material-ui/core/styles';

import { VideoBackground } from '../Video';
import HeroHeading from './HeroHeading';
import HeroMenu from '../Hero/HeroMenu';
import Image from '../Image';
//import HeroButtonList from '../../Hero/HeroButtonList';


var titleStyle = {
  color: 'primary',
};

const styles = (theme) => createStyles({
  dark: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
  },
  light: {
    backgroundColor: "rgba(256, 256, 256, 0.5)",
  },
  tagline: {
    color: theme.palette.primary.main,
  },
  root: {
    backgroundImage: 'URL("\includes\images\bkgd\soccer_field.jpg")',
  },
  video: {

  }
})

class HeroVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      onSnapshot: null,
    };

  }

  render() {
    const { source, title, tagline, menu, classes, height, ...props } = this.props;

    let pxHeight;
    switch(height) {
      case 'sm': pxHeight = "300px"
        break;
      case 'md': pxHeight = "500px"
        break;
      case 'lg': pxHeight = "700px"
        break;
      default: pxHeight = "";
    };

    return (
      <Box>
        

      <Grid Container justify="center" classes={{ root: classes.root }} height={ pxHeight } { ...props }>

        { false && <VideoBackground source={ source } type="video/mp4" classes={{ root: classes.video }}/>}

        <Box classes={{ root: classes.light }}>
          <Grid container direction="column" justify="space-evenly" alignItems="stretch" flexgrow={1}>

            <Grid item>
              <HeroHeading variant="h1" component="h1" align="center" mt={4} style={ titleStyle }>
                { title }
              </HeroHeading>
            </Grid>

            <Grid item>
              <Box>
                {/*<Button type="button" className="btn btn-outline-dark" color="primary" size="large">
                  Find Your Sport
                </Button>*/}
              </Box>
            </Grid>

            <Grid item align="center">
              <Typography variant="h3" classes={{ root: classes.tagline }}>
                { tagline }
              </Typography>
              { menu && <HeroMenu xs={0} md={10} color="primary"/> }
            </Grid>

          </Grid>
        </Box>
      </Grid>
      </Box>
    );
  }
}

export default withStyles(styles)(HeroVideo);
