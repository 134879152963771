

const defaultOverrides = {
  MuiButtonBase: {

  }
}


export default defaultOverrides;
