import React from 'react';
import { styled } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const FieldStatusButton = styled(Button)({
  background: 'Green',
  border: 0,
  borderRadius: 5,
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  color: 'white',
  height: 48,
  padding: '8px',
  margin: '10px',
  fontSize: '1.25rem',
  LineHeight: 5
});

export default function StyledComponents() {
  return <FieldStatusButton p={2}>Field
  Status</FieldStatusButton>;
}
