import React, { Component } from 'react';
import {
 BrowserRouter as Router,
 Route,
} from "react-router-dom"
import { ThemeProvider } from '@material-ui/core/styles';

import { withAuthentication } from '../core/Session';
import theme from '../core/Theme';

import Header from '../Header';
import Footer from '../Footer';

import HomePage from '../Home';
import SportPage from '../SportPage';
import TestPage from '../TestPage';
import FieldStatusPage from '../FieldStatusPage';
import BoardPage from '../../routes/BoardPage';
import SchedulePage from '../SchedulePage';

import * as ROUTES from '../../constants/routes';


const footer = {
  contactInfo: {
    phone: "5123310438",
    email: "office@TandCsports.org",
    header: "T&C Sports",
    subtitle: "Office Hours",
    hours: [
      {
        days: "Mon - Fri",
        hours: "9am - 4pm"
      }
    ],
  },
  links: [
    {
      name: "About T&C",
      link: "/test",
    },{
      name: "Contact Us",
      link: "/test",
    },{
      name: "Privacy Policy",
      link: "/test",
    },{
      name: "Sitemap",
      link: "/test2",
    }
  ],
}



class App extends Component{
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      hosts: [],
      onSnapshot: null,
      hostConfig: null,
      navMenu: null,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    const { firebase, hostId } = this.props;

    firebase.initialize().then( () =>
      {
        const onSnapshot = firebase.hostConfig(hostId).onSnapshot( (snapshot) =>
          {
            this.setState({ hostConfig: snapshot.data() });
          }, (error) => {
            console.error("Unable to access HostConfig: " + error);
          }
        );

        this.setState({ onSnapshot: onSnapshot });
      }
    );
  }

  componentWillUnmount() {
    if (this.state.onSnapshot)
      this.state.onSnapshot();
  }

  render() {
    const { hostConfig } = this.state;

    return (
      <Router>
        <ThemeProvider theme={ theme }>
          <Header hostConfig = { hostConfig }/>

          <Route exact path={ROUTES.HOME} component={HomePage} />
          <Route exact path={ "/soccer" } component={SportPage} />
          <Route path={ "/soccer/board" } component={BoardPage} />
          <Route path={ "/test" } component={TestPage} />
          <Route path={ "/FieldStatus" } component={FieldStatusPage} />
          <Route path={ "/Schedule" } component={SchedulePage} />

          <Footer
            { ...footer }
          />

        </ThemeProvider>
      </Router>
    );
  }
}

export default withAuthentication(App);
