import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { compose } from "recompose";

import { withFirebase } from '../../core/Firebase';


const styles = (theme) => createStyles({
  root: {

  },
});


class Hours extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactInfo: {
        email: null,
        phone: null,
        hours: null,
      },
    };

    this.onClick = this.onClick.bind(this);
  }

  static defaultProps = {
    variant: "body1"
  }

  onClick(event) {
  };

  render() {
    const { hours, color, variant } = this.props;

    return (
      <Grid container xs={12}>
        { hours && hours.map( (hoursItem,key) => (
          <Grid container xs={12}>
            <Grid item xs={6}>
              <Box p={1}>
                <Typography
                  align="right"
                  color={ color }
                  variant={ variant }
                >
                  { hoursItem.days }
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box p={1}>
                <Typography
                  align="left"
                  color={ color }
                  variant={ variant }
                >
                  { hoursItem.hours }
                </Typography>
              </Box>
            </Grid>
          </Grid>
        ))}
      </Grid>
    );
  }
}

export default compose(
  withFirebase,
  withStyles(styles),
)(Hours);
