import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles, createStyles } from '@material-ui/core/styles';


const styles = (theme) => createStyles({
  root: {
  },
});


class FooterLinks extends Component {

  render() {
    const { links, ...props } = this.props;

    return (
      <Grid container align="center">
        {
          links?.map( footerlink => (
            <Grid item xs={4} sm={12}>
              <Button
                { ...props }
                href={ footerlink.link }
              >
                <Typography
                  variant="body1"
                  justify="right"
                >
                  { footerlink.name }
                </Typography>
              </Button>
            </Grid>
          ))
        }
      </Grid>
    );
  }
}


export default withStyles(styles)(FooterLinks);
