import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import PhoneIcon from '@material-ui/icons/Phone';


class Phone extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };

    this.onClick = this.onClick.bind(this);
  }

  static defaultProps = {
    delimiter: ".",
    button: true,
    color: "primary",
    variant: "body1",
  }

  onClick(event) {

  }

  render() {
    let { number, button, delimiter, ...props } = this.props;
    if (!number) return null;

    number = number.toString().replace(/[^0-9+]+/g, '').replace(/\s+/, '');

    let formatted_number = number;

    if (formatted_number.length === 8) {
      formatted_number = number.replace(/([0-9]{2})([0-9]{3})([0-9]{3})/,
        "$1" + delimiter + "$2" + delimiter + "$3");
    } else if (formatted_number.length === 10) {
      formatted_number = number.replace(/([0-9]{3})([0-9]{3})([0-9]{4})/,
        "$1" + delimiter + "$2" + delimiter + "$3");
    } else if (formatted_number.indexOf('+') === 0) {
      formatted_number = number.replace(/([0-9]{2})([0-9]{3})([0-9]{3})([0-9]{4})/,
        "$1" + delimiter + "$2" + delimiter + "$3" + delimiter + "$4");
    }

    if (button) {
        return (
          <Button
            { ...props }
            href={`tel:${number}`}
            onClick = { this.onClick }
            startIcon={<PhoneIcon { ...props }/>}
          >
            <Typography { ...props } >
              { formatted_number }
            </Typography>
          </Button>
        );
    } else {
        return (
          <span { ...props }>
            { formatted_number }
          </span>
        );
    }
  }
}


export default Phone;
