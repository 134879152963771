import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Typography from '@material-ui/core/Typography';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';


const styles = createStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  card: {
    height: "100%",
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
}));



class Pricing extends Component {

  render() {
    const { options, classes, ...props } = this.props;

    return (
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="lg" component="main" { ...props }>
          <Grid container spacing={5} alignItems="stretch">
            { options && options.map((option) => (
              // Enterprise card is full width at sm breakpoint
              <Grid item key={option.title} xs={12} sm={option.title === 'Enterprise' ? 12 : 6} md={3}>
                <Card classes={{ root: classes.card }}>
                  <CardHeader
                    title={option.title}
                    subheader={option.subheader}
                    titleTypographyProps={{ align: 'center' }}
                    subheaderTypographyProps={{ align: 'center' }}
                    action={option.star ? <StarIcon /> : null}
                    className={classes.cardHeader}
                  />
                  <CardContent>
                    { option.prices && option.prices.map((tier) => (
                      <div className={classes.cardPricing}>
                        <Typography variant="h6" color="textSecondary">
                          { tier.name }
                        </Typography>
                        <Typography component="h2" variant="h3" color="textPrimary">
                          ${ tier.price }
                        </Typography>
                        <Typography variant="h6" color="textSecondary">
                          { tier.priceBasis }
                        </Typography>
                      </div>
                    ))}
                    <ul>
                      {option.description.map((line) => (
                        <Typography component="li" variant="subtitle1" align="center" key={line}>
                          {line}
                        </Typography>
                      ))}
                    </ul>
                  </CardContent>
                  <CardActions>
                    <Button fullWidth variant={option.buttonVariant} color="primary">
                      {option.buttonText}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Pricing);
