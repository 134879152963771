import FirebaseApp from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/database';
import 'firebase/firestore';

//import HostConfig from './HostConfig';

import * as COLLECTIONS from '../../../constants/collections';



class Firebase {

  // Initialize Firebase with reserved INIT.JSON URL
  productionInit = () => {
    return fetch( '/__/firebase/init.json' )
    .then(
      response => {
        return (
          response.ok
          ? this.config = response.json()
          : Promise.reject( response )
        );
      }
    )
    .then(
      config => {
        FirebaseApp.initializeApp( config );
        this.auth = FirebaseApp.auth();
        this.db = FirebaseApp.firestore();
        console.debug( "Firebase Initialized" );
      }
    );
  }

  // Initialize Firebase with Dev INIT.JSON
  developmentInit = () => {
    return import( "./firebase." + process.env.NODE_ENV + ".js"  )
    .then(
      module => {
        this.config = module.FIREBASE_INIT_CONFIG;
        return this.config;
      }
    )
    .then(
      config => {
        FirebaseApp.initializeApp( config );
        this.auth = FirebaseApp.auth();
        this.db = FirebaseApp.firestore();
        console.debug( "Firebase Initialized (DEV SERVER)" );
      }
    );
  }

  initialize = () => {
    if (!this.initPromise)
      if (process.env.NODE_ENV === "production") {
        this.initPromise = this.productionInit();
      } else {
        this.initPromise = this.developmentInit();
      }
    return this.initPromise;
  }

  constructor() {
    this.config = null;
    this.auth = null;
    this.db = null;
    this.initPromise = null;
    // Initialize Firebase from firebase reserved URLs
    // Requires 'firebase serve' for local dev
    //this.initialize = fetch( '/__/firebase/init.json' )

    this.initialize()
    .then(
      () => {
        try {
          this.auth = FirebaseApp.auth();
          this.db = FirebaseApp.firestore();
          return this;
        }
        catch(error) {
          console.error(error);
          return Promise.reject();
        }
      }
    );
  }


  // *** Generic API ***
  collection = (path) => this.db.collection(path);


  // *** Auth API ***
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
  this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);


  // *** User API ***
  user = uid => this.db.collection('users').doc(uid);

  users = () => this.db.collection('users');


  // *** HostConfig API ***
  hostConfig(uid) {
    return this.db.collection(COLLECTIONS.HOSTS).doc(uid);
  }

  hosts = () => this.db.collection(COLLECTIONS.HOSTS);

  host2 = fields => {
    if( fields.id )
      return this.db.collection(COLLECTIONS.HOSTS).doc(fields.id)
    else {}
      /*return fields.map( key => {
        this.db.collection(COLLECTIONS.HOSTS).where(key, "==", fields[key]).get();
      });*/
  }



  // *** Navigation API ***
  navigation = uid => this.db.collection(COLLECTIONS.NAV).doc(uid);

  navMenu = () => this.db.collection('Navigation/NavMenu');


}

export default Firebase;
