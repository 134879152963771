import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
//import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles, createStyles } from '@material-ui/core/styles';

import { VideoBackground } from '../../Video';
import HeroHeading from '../HeroHeading';
import HeroMenu from '../../Hero/HeroMenu';
import Image from '../../Image';
//import HeroButtonList from '../../Hero/HeroButtonList';

//import css from './HeroVideo.module.css';

var titleStyle = {
  color: 'primary',
};

const styles = (theme) => createStyles({
  dark: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
  },
  light: {
    backgroundColor: "rgba(256, 256, 256, 0.5)",
  },
  tagline: {
    color: theme.palette.primary.main,
  },
  root: {
    position: "relative",
    minHeight: "300px",
    maxHeight: "800px",
    overflow: "hidden",
    height: "100%",
    width: "100%",
  },
  image: {
    width: "100%",
    height: "100%",
  },
  overlay: {
    position: "absolute",
    top: 0,
    color: "#f1f1f1",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
  }

})

class HeroVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      onSnapshot: null,
    };

  }

  componentDidMount() {
    this.setState({ loading: true });

    if ( this.props.menu && this.props.menu.linkRef ) {
      const onSnapshot = this.props.menu.linkRef.onSnapshot(
        (menuDoc) => {
          const menu = {
            uid: menuDoc.id,
            ...menuDoc.data(),
          };
          this.setState({
            menu: menu,
            loading: false,
          });
        }
      );

      this.setState({ onSnapshot: onSnapshot });
    }
  }

  componentWillUnmount() {
    if ( this.state.onSnapshot )
      this.state.onSnapshot();
  }

  render() {
    const { source, title, tagline, menu, classes, height, ...props } = this.props;

    let pxHeight;
    switch(height) {
      case 'sm': pxHeight = "300px"
        break;
      case 'md': pxHeight = "500px"
        break;
      case 'lg': pxHeight = "700px"
        break;
      default: pxHeight = "";
    };

    return (
      <Box classes={{ root: classes.root }} height={ pxHeight } { ...props }>
        <Image source={ source } classes={{ root: classes.image }}/>

        <Box classes={{ root: classes.light }}>
          <Grid container direction="column" justify="space-evenly" alignItems="stretch" flexgrow={1}>

            <Grid item>
              <HeroHeading variant="h1" component="h1" align="center" mt={4} style={ titleStyle }>
                { title }
              </HeroHeading>
            </Grid>

            <Grid item>
              <Box>
                {/*<Button type="button" className="btn btn-outline-dark" color="primary" size="large">
                  Find Your Sport
                </Button>*/}
              </Box>
            </Grid>

            <Grid item align="center">
              <Typography variant="h3" classes={{ root: classes.tagline }}>
                { tagline }
              </Typography>
              { menu && <HeroMenu xs={0} md={10} color="primary"/> }
            </Grid>

          </Grid>
        </Box>
      </Box>
    );
  }
}

export default withStyles(styles)(HeroVideo);
