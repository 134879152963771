import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { compose } from "recompose";

import Phone from './Phone';
import Email from './Email';
import { withFirebase } from '../core/Firebase';
import Hours from './Hours';


const styles = (theme) => createStyles({
  root: {

  },
});


class ContactInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactInfo: {
        email: null,
        phone: null,
      },
    };

    this.onClick = this.onClick.bind(this);
  }

  static defaultProps = {
      button: true,
      align: "center",
      alignItems: "center",
  };


  componentDidMount() {
    this.setState({ loading: true });

    const { firebase } = this.props;
    const collection = "/contactInfo";
    const doc = 'default';


    firebase.initialize().then( () =>
      {
        const onSnapshot = firebase.collection(collection).doc(doc).onSnapshot( (snapshot) =>
          {
            this.setState({ contactInfo: snapshot.data() });
          }, (error) => {
            console.error("Unable to access " + collection + "/" + doc + ": " + error);
          }
        );

        this.setState({ onSnapshot: onSnapshot });
      }
    );
  }

  componentWillUnmount() {
    if (this.state.onSnapshot)
      this.state.onSnapshot();
  }

  onClick(event) {
  };

  render() {
    const { hours, phone, address, isLinked, className, header, subtitle, ...props } = this.props;
    const { email } = this.state.contactInfo;

    return (
      <Box { ...props }>
        <Grid container { ...props }>
          <Grid item xs={12}>
            <Typography variant="h4">{ header }</Typography>
            <Typography variant="subtitle1" >{ subtitle }</Typography>
          </Grid>
          <Grid container { ...props }>
            <Grid item xs={12} sm={12} md={12}>
              <Hours hours={ hours } { ...props } />
            </Grid>
            <Grid container xs={12} md={12} { ...props }>
              <Grid item xs={12} md={12}>
                <Email email={ email } { ...props } />
              </Grid>
              <Grid item xs={12} md={12}>
                <Phone number={ phone } { ...props } />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default compose(
  withFirebase,
  withStyles(styles),
)(ContactInfo);
