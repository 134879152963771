import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

class Events extends Component {

  render() {
    return (
      <Box>
        <Typography
          variant="body1"
          component="p"
          align="center"
          paragraph={true}
        >
          Events Happening Weekly! We have some great events check out our calendar for more information.
        </Typography>
        <Button variant="outlined" color="primary">EVENTS CALENDAR</Button>
      </Box>
    )
  }
}

export default Events;
