import React, { Component } from 'react';
import { withTheme } from '@material-ui/core/styles';
//import { styled } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

class CTAButton extends Component {
  render() {
    const { children, ...props } = this.props;


    return (
      <Button
        color='secondary'
        variant='contained'
        { ...props }
      >
        { children }
      </Button>
    );
  }
}
/*

 */


export default withTheme(CTAButton);
