import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';


import css from './HeroHeading.module.css';

class HeroHeading extends Component {

  render() {
    const { children, variant, component, ...props } = this.props;

    return (
      <Box className={css.root} { ...props } >
        <Typography variant={ variant } component={ component }>
          { children }
        </Typography>
      </Box>
    );
  }
}

export default HeroHeading
