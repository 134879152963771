import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';

//import { Player } from 'video-react';

import styles from './VideoPlayer.module.css';

class VideoPlayer extends Component {

  render() {
    const { source, elevation, autoplay, loop } = this.props;

    return(
      <Paper
        className={ styles.root }
        elevation={ elevation }
        borderRadius={16}
      >
        {/*<Player>
          <source src={ source } />
        </Player>*/}
        <video
          autoPlay={ autoplay }
          loop={ loop }
          muted
          className={ styles.Video }
          width="100%"
          controls>
            <source src={ source } type="video/mp4" />
            Your browser does not support the video tag. We will show an image slideshow!
        </video>
      </Paper>
    )
  }
}

export default VideoPlayer;
