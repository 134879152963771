import React, { Component } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';


const styles = ({ palette, breakpoints }: Theme) => createStyles({
  paper: {
    backgroundColor: palette.primary.main,
    color: palette.primary.contrastText,
    position: "relative",
  },
  headerText: {
    color: palette.primary.contrastText,
    textTransform: "uppercase",
  },
  secondaryText: {
    color: palette.primary.main,
    fontStyle: 'italic',
    fontSize: '2.5rem',
    fontWeight: 400,
    display: 'block',
    position: "relative",
  },
  linkText: {
    color: palette.primary.contrastText,
    textTransform: "capitalize",
    textDecoration: "none",
    '&:hover': {
      color: palette.secondary.main,
    },
  },
  centerGrid: {
    [breakpoints.up('sm')]: {
      borderLeftWidth: '1px',
      borderLeftStyle: 'solid',
      borderRightWidth: '1px',
      borderRightStyle: 'solid',
    },
  },
  buttonLabel: {
    color: palette.primary.main,
    '&:hover': {
      color: palette.secondary.main,
    },
    '&:focus': {
      color: palette.secondary.main,
    },
  },
  button: {
    display: "block",
    '&:hover': {
      backgroundColor: "rgba(0,0,0,0)",
    },
    '&:focus': {
      outline: "none",
    },
  },
  group: {
    justifyContent: "center",
    display: "flex",
  },
  groupTextBox: {
    justifyContent: "center",
    flexGrow: 1,
  },
  divider: {
    height: "50px",
    width: "2px",
    backgroundColor: palette.primary.contrastText,
    margin: "auto",
  }
});

class ShortcutList extends Component {

  render() {
    const { linkGroups, classes, elevation, mt, mb, ...props } = this.props;
    const p = 2;
    const colWidth = 12 / linkGroups.length;

    return (
      <Grid container item { ...props }>
        <Box mt={ mt } mb={ mb } width="100%">
          <Typography variant="p" align="left" classes={{ root: classes.secondaryText }}>
            I Want To...
          </Typography>
          <Paper
            elevation={ elevation }
            align="center"
            classes={{ root: classes.paper }}
          >
              <Grid container item xs={12} display="flex" justify="center" alignItems="stretch">
                { linkGroups?.map( (group, key) => (

                  <Grid item xs={12} sm={ colWidth }>
                    <Box classes={{ root: classes.group }}>
                      { (key != 0) && <Divider orientation="vertical" flexItem classes={{ root: classes.divider }}/> }
                      <Box p={1} classes={{ root: classes.groupTextBox }}>

                        <Typography variant="h3" component="p" classes={{ root: classes.headerText }}>
                          { group.heading }
                        </Typography>

                        { group.links?.map( (link) => (
                          <Button
                            link={ link.to }
                            classes={{ root: classes.button, label: classes.buttonLabel }}
                          >
                            <Typography variant="h5" component="p" classes={{ root: classes.linkText }}>
                              { link.label }
                            </Typography>
                          </Button>
                        ))}

                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>

          </Paper>
        </Box>
      </Grid>
    );
  }
}


/*export default compose(
  withTheme,
  withStyles(styles, { withTheme: true }),
)(ShortcutList);*/
export default withStyles(styles, { withTheme: true })(ShortcutList);
