import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import MailOutlineIcon from '@material-ui/icons/MailOutline';


class Email extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };

    this.onClick = this.onClick.bind(this);
  }

  static defaultProps = {
    button: true,
    color: "primary",
    variant: "body1",
  }

  onClick(event) {

  }

  render() {
    const { email, button, ...props } = this.props;
    if (!email) return null;

    let formatted_email;
    formatted_email = email;

    if (button) {
        return (
          <Button
            { ...props }
            href={`mailto:${email}`}
            onClick = { this.onClick }
            startIcon={<MailOutlineIcon { ...props }/>}
          >
            <Typography { ...props } >
              { formatted_email }
            </Typography>
          </Button>
        );
    } else {
        return (
          <span { ...props }>
            { formatted_email }
          </span>
        );
    }
  }
};


export default Email;
