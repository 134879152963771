import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { createStyles, withStyles } from '@material-ui/core/styles';

import HeroVideo from '../Hero/HeroVideo';
import ThorGuard from './ThorGuard';
import FieldStatusTable from './FieldStatusTable';

const allStatus=[
  {
    label: "Baseball",
    notes: "Cages and Outfield only. Infields are too soft for play.",
    chips: [
      {
        label: "Cages and Outfield only",
        color: "red",
      }
    ],
    fields: [
      {
        label: "Field 7",
        status: false,
      },
      {
        label: "Field 10",
        status: false,
      },
      {
        label: "Fields 12-15",
        status: true,
      },
      {
        label: "Field 21",
        status: true,
      },
    ]
  },
  {
    label: "Football/ Lacrosse",
    notes: "",
    chips: [
      {
        label: "Check at 4pm",
        color: "yellow",
      }
    ],
    fields: [
      {
        label: "Field 1",
        status: true,
      },
      {
        label: "Field 2",
        status: true,
      },
      {
        label: "Fields 11",
        status: true,
      },
    ]
  },
  {
    label: "Soccer",
    notes: "",
    chips: [
      {
        label: "Lights Malfunctioning",
      }
    ],
    fields: [
      {
        label: "Field 3-6",
        status: true,
      },
      {
        label: "Field 20",
        status: true,
      },
      {
        label: "Fields 22-28",
        status: true,
      },
      {
        label: "Fields 31-36",
        status: true,
      },
    ]
  },
  {
    label: "Softball",
    notes: "",
    chips: [
      {
        label: "All Softball Fields Closed",
        color: "red",
      }
    ],
    fields: [
      {
        label: "Field 8",
        status: false,
      },
      {
        label: "Field 9",
        status: false,
      },
    ]
  },
];


const styles = (theme) => createStyles({
  root: {

  },
});


class FieldStatusPage extends Component {
  render() {
    const { ...props } = this.props;

    return (
      <Box align="center">

        <HeroVideo
          source="https://media.istockphoto.com/videos/children-playing-soccer-video-id481273390"
          title="T&C Soccer"
          height="sm"
        />

        <Box { ...props } pt={8} mb={24}>
          <Grid container item xs={12} md={10} { ...props } spacing={4}>
            <Grid item xs={12} md={6}>
              <FieldStatusTable sports={ allStatus }/>
            </Grid>
            <Grid item xs={12} md={6}>
              <ThorGuard account="tandcsports" mobile />
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  }
}

export default withStyles(styles)(FieldStatusPage);
