import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Image from '../../Image';

const styles = (theme) => createStyles({
  logo: {
    height:"12rem",
    transition: "height ease .3s",
  },
  shrink: {
    height: "5rem",
  },
  alignRight: {
    justifyContent: "flex-end",
    textAlign: "end",
  },
  logoBox: {
    width: "100%",
    flex: "0 1 15%",
    alignSelf: "flex-start",
  },
});




class Logo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shrink: null,
      shrinkAt: 200,
    }

    this.setLogoSize = this.setLogoSize.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.setLogoSize);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.setLogoSize);
  }

  shrinkable() {
    const distanceY = window.pageYOffset ||
      document.documentElement.scrollTop;

    return distanceY > this.state.shrinkAt;
  }

  setLogoSize() {
    const shrink = this.shrinkable();
    if (shrink !== this.state.shrink)
      this.setState({
        shrink: shrink,
      });
  }


  render() {
    const { src, classes, shrink, shrinkAt, shrinkLeft, shrinkRight, ...props } = this.props;
    if( shrinkAt ) this.setState({ shrinkAt: shrinkAt });

    const isShrunk = (shrink || shrinkLeft || shrinkRight) & this.shrinkable();
    var logo = isShrunk ? clsx( classes.logo, classes.shrink ) : classes.logo;
    const justifyContent = shrinkRight ? "flex-end" : shrinkLeft ? "flex-start" : "center";

    return (
      <Box classes={{ root: classes.logoBox }} { ...props } p={0} pl={2} pr={2} display="flex" justifyContent={ justifyContent }>
        <Link component={RouterLink} to="/" classes={{ root: logo }}>
          <Image src={ src } height="100%" />
        </Link>
      </Box>
    );
  }

}

export default withStyles(styles)(Logo);
