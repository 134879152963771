import React, { Component } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { fade } from '@material-ui/core/styles/colorManipulator';


import css from './HeroMenu.module.css';


const styles = ({ palette }: Theme) => createStyles({
  paper: {
    backgroundColor: fade("#FFF", 0.6),
    color: palette.primary.main,
    position: "relative",
  },
  divider: {
    background: palette.primary.primary,
  },
  linkText: {
    color: palette.primary.main,
    textTransform: "capitalize",
    textDecoration: "none",
    '&:hover': {
      color: palette.secondary.main,
    },
  },
  buttonLabel: {
    color: palette.primary.main,
    '&:hover': {
      color: palette.secondary.main,
    },
    '&:focus': {
      color: palette.secondary.main,
    },
  },
  button: {
    color: palette.primary.primary,
    display: "block",
    '&:hover': {
      backgroundColor: "rgba(0,0,0,0)",
    },
    '&:focus': {
      outline: "none",
    },
  },
});

class HeroMenu extends Component {

  render() {
    const { classes, elevation, ...props } = this.props;

    return (
        <Grid container justify="space-between" alignItems="stretch" classes={{ root: classes.root }} { ...props }>
          <Paper
            elevation={ elevation }
            align="center"
            className={ css.Paper }
            classes={{ root: classes.paper }}
          >
            <Box p={2}>
              <Grid container justify="space-evenly" alignItems="stretch" >
              <Grid item xs={0} md={1.5} m={4}>
                <Button classes={{ root: classes.button, label: classes.buttonLabel }}>
                  <Typography variant="h2" classes={{ root: classes.linkText }}>
                    Baseball
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs={0} md={1.5}>
                <Button classes={{ root: classes.button, label: classes.buttonLabel }}>
                  <Typography variant="h2" classes={{ root: classes.linkText }}>
                    Basketball
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs={0} md={1.5}>
                <Button classes={{ root: classes.button, label: classes.buttonLabel }}>
                  <Typography variant="h2" classes={{ root: classes.linkText }}>
                    Football
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs={0} md={1.5}>
                <Button classes={{ root: classes.button, label: classes.buttonLabel }}>
                  <Typography variant="h2" classes={{ root: classes.linkText }}>
                    Lacrosse
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs={0} md={1.5}>
                <Button classes={{ root: classes.button, label: classes.buttonLabel }}>
                  <Typography variant="h2" classes={{ root: classes.linkText }}>
                    Soccer
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs={0} md={1.5}>
                <Button classes={{ root: classes.button, label: classes.buttonLabel }}>
                  <Typography variant="h2" classes={{ root: classes.linkText }}>
                    Softball
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs={0} md={1.5}>
                <Button classes={{ root: classes.button, label: classes.buttonLabel }}>
                  <Typography variant="h2" classes={{ root: classes.linkText }}>
                    Volleyball
                  </Typography>
                </Button>
              </Grid>
              </Grid>

            </Box>
          </Paper>
        </Grid>
    );
  }
}


/*export default compose(
  withTheme,
  withStyles(styles, { withTheme: true }),
)(ShortcutList);*/
export default withStyles(styles, { withTheme: true })(HeroMenu);
