import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import HeroVideo from '../Hero/HeroVideo';
import NewsFeed from '../NewsFeed';
import Events from '../Calendar/Event';
import InstaCarousel from '../SocialStream/Carousel';
import ShortcutList from '../Navigation/ShortcutList';
import Section, { Heading, Subtitle } from '../Section';
import Carousel from '../Carousel';
import Pricing from '../Pricing';
import TextBox from '../TextBox';
import Image from '../Image';
import RegisterButton from '../Button/Register';
import Table from '../Table';

/*
Divisions
Board
Select tryouts
Schedules
Maps/Lightning Safety
Programs
FAQ
Uniforms
More:
- Parents Corner
- Coaches Corner
- Volunteer Corner
- Ref Corner
- Docs
 */


const styles = ({ palette }: Theme) => createStyles({
  root: {

  },
  CalendarSection: {
    backgroundImage: "url('/includes/images/bkgd/baseball_field.jpg')",
    backgroundPosition: "center",
    backgroundSize: "cover",
    opacity: "0.3",
  },
  ArticleSection: {
    //backgroundImage: "url('/includes/images/basketball.jpg')" ,
    backgroundColor: "gray",
    backgroundPosition: "center",
    backgroundSize: "cover",
    opacity: "0.5",
  },
  TestimonialSection: {
    backgroundImage: "url('/includes/images/bkgd/football_grass.jpg')",
    backgroundPosition: "center",
    backgroundSize: "cover",
    opacity: "1",
  },
  SportsCardSection: {
    backgroundImage: "url('/includes/images/baseball2.jpg')" ,
    backgroundPosition: "center",
    backgroundSize: "cover",
    opacity: "0.25",
  },
  SportsmanshipSection: {
    backgroundColor: "rgba(2,173,231,0.8)",
    backgroundImage: "url('/includes/images/refSoccer.jpg')",
    //background: "rgba(2,173,231,0.5), url('/includes/images/refSoccer.jpg')",
    backgroundPosition: "center",
    backgroundSize: "cover",
    opacity: ".3",
  },
  MapSection: {
    backgroundImage: "url('/includes/images/bkgd/campus_sky.jpg')",
    //backgroundColor: "gray",
    backgroundPosition: "bottom",
    backgroundSize: "cover",
    opacity: ".2",
  },
  SponsorSection: {
    backgroundImage: "url('/includes/images/bkgd/baseball_field.jpg')",
    backgroundPosition: "bottom",
    backgroundSize: "cover",
    opacity: "1",
  },
  contrastText: {
    color: palette.primary.contrastText,
  },
  shadowText: {
    //color: palette.primary.contrastText,
    textShadow: "0px 0px 2px white",
  },
  SocialSection: {
    //backgroundImage: "url('/includes/images/field.jpg')" ,
    //backgroundColor: palette.secondary.main,
    backgroundPosition: "center",
    backgroundSize: "cover",
    opacity: "0.25",
  },
  overlayLight: {
    backgroundColor: "rgba(256,256,256,.7)",
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  blue: {
    backgroundColor: "rgba(0,100,200,.2)",
  },
  sportmanshipCard: {
    height: "200px",
  },
});

const news = [
  {
    image: "/includes/images/refSoccer2.jpg",
    title: "Become A Referee",
    subtitle1: "T&C Soccer",
    subtitle2: "Apply Today!",
    button: "Learn More",
  },
  {
    image: "/includes/images/golfFlyer.png",
    title: "Golfers Needed",
    subtitle1: "",
    subtitle2: "",
    button: "Sign Up",
  },
  {
    image: "/includes/images/baseball2.jpg",
    title: "Open Registration",
    subtitle1: "T&C Baseball",
    subtitle2: "Apply Today!",
    button: "Register Now!",
  },
];

const leaguesAll = [
  {
    title: 'Division 4 Rec',
    subheader: 'U4 - U10',
    prices: [
      {
        name: '',
        price: '80',
        priceBasis: 'U4 (4v4)',
      },
      {
        name: '',
        price: '90',
        priceBasis: 'U6 (5v5)',
      },
      {
        name: '',
        price: '150',
        priceBasis: 'U8 (7v7)',
      },
      {
        name: '',
        price: '165',
        priceBasis: 'U10 (9v9)',
      },
    ],
    description: ['Volunteer Coached'],
    buttonText: 'Register Now',
    buttonVariant: 'outlined',
  },
  {
    title: 'Division 3 Rec',
    subheader: 'U11 - U19',
    prices: [
      {
        name: '',
        price: '190',
        priceBasis: '',
      },
    ],
    description: [
      '11 v 11',
      'Full Size Field',
      'Competitive Training',
      'Certified Coaches',
    ],
    buttonText: 'Register Now',
    buttonVariant: 'outlined',
  },
  {
    title: 'T&C Academy',
    star: true,
    subheader: 'U9 - U10',
    prices: [
      {
        name: '',
        price: '215',
        priceBasis: '',
      },
    ],
    description: [
      '11 v 11',
      'Full Size Field',
      'Competitive Training',
      'Certified Coaches',
    ],
    buttonText: 'Register Now',
    buttonVariant: 'outlined',
  },
  {
    title: 'Competitive Select',
    subheader: 'U11 - U19',
    prices: [
      {
        name: '',
        price: '200',
        priceBasis: 'Division 2',
      },
      {
        name: '',
        price: '450',
        priceBasis: 'Super 2',
      },
      {
        name: '',
        price: '500',
        priceBasis: 'Challenger',
      },
      {
        name: '',
        price: '550',
        priceBasis: 'Division 1',
      },
      {
        name: '',
        price: '600',
        priceBasis: 'State Classic',
      },
    ],
    description: [],
    buttonText: 'Learn More',
    buttonVariant: 'outlined',
  },
];

const shortcuts=[
  {
    //heading: "",
    links: [
      {
        label: "Find Program Info",
        to: "",
      },
      {
        label: "See Schedules",
        to: "",
      },
      {
        label: "Get Directions",
        to: "",
      },
      {
        label: "Check Field Status",
        to: "",
      },
      {
        label: "Become A Coach",
        to: "",
      },
      {
        label: "Find Forms",
        to: "",
      },
    ]
  },
];

const headers = [
  {
    property: "Age",
    label: "Age Group",
  },
  {
    property: "birthYear",
    label: "Birth Year",
  },
  {
    property: "division",
    label: "Division",
  },
  {
    property: "registrationFee",
    label: "Registration Fee",
  },
  {
    property: "registrationLink",
    label: "Registration Link",
  },
  {
    property: "registrationLink",
    label: "",
  }
];

const headers2 = [
  {
    property: "Age",
    label: "Age Group",
  },
  {
    property: "division",
    label: "Division",
  },
  {
    property: "registrationFee",
    label: "Registration Fee",
  },
  {
    property: "registrationLink",
    label: "Registration Link",
  },
];

const rows = [
    {
      age: "U4",
      birthYear: "2016",
      division: "D4 - Recreation",
      registrationFee: "$80",
      registrationLink: "",
    },
    {
      age: "U5 - U6",
      birthYear: "2016",
      division: "D4 - Recreation",
      registrationFee: "$80",
      registrationLink: "",
    },
    {
      age: "U7 - U8",
      birthYear: "2016",
      division: "D4 - Recreation",
      registrationFee: "$80",
      registrationLink: "",
    },
    {
      age: "Division 4 (U4)",
      birthYear: "2016",
      division: "D4 - Recreation",
      registrationFee: "$80",
      registrationLink: "",
    },
    {
      age: "Division 4 (U4)",
      birthYear: "2016",
      division: "D4 - Recreation",
      registrationFee: "$80",
      registrationLink: "",
    },
    {
      age: "Division 4 (U4)",
      birthYear: "2016",
      division: "D4 - Recreation",
      registrationFee: "$80",
      registrationLink: "",
    },

];


class App extends Component{

  render() {
    const { classes } = this.props;

    return (
      <Box align="center">
        <HeroVideo
          source="https://media.istockphoto.com/videos/children-playing-soccer-video-id481273390"
          title="T&C Soccer"
          height="md"
        />

        <Section>
        <Grid container item justify="center" spacing={4} xs={10} alignItems="center">
          <ShortcutList linkGroups={ shortcuts } elevation={10} xs={10} md={4} align="left" classes={{ root: classes.shortcutList }}/>
          <Grid item xs={10} md={4}>
            <TextBox>
            <div>
              <Heading variant="h5">
                Anouncements
              </Heading>
              <Box p={2}>
              <p>Registration Opens June</p>
              <p>Evaluations Open August</p>

              <p>Registration Opens June</p>
              <p>Evaluations Open August</p>

              </Box>
            </div>
            </TextBox>
          </Grid>
          <Grid item xs={10} md={4}>
            <Button href="#Divisions" variant="outlined" color="secondary">View Division Registration Information</Button>
            <Table headers={ headers2 } rows={ rows } />
          </Grid>
        </Grid>
        </Section>

        <Section classes={{ background: classes.SportsmanshipSection }}>
          <Heading>
            Soccer @ T&C Sports
          </Heading>
          <Grid container item xs={10} spacing={8}>
            <Grid item xs={12} md={6} >
              <Typography
                variant="body1"
                component="p"
                align="justify"
                paragraph={true}
              >
                Developing good character in our players is a core element of T&C’s mission and is demonstrated by every team in our youth soccer leagues.
                Whether you here to grow a lifelong passion for the sport or want to advance your skills and compete, we have a team ready for you.
              </Typography>
            </Grid>
            <Grid item xs={11} md={6} >
              <Carousel classes={{ card: classes.sportmanshipCard }} fade="true">
                <img src="https://cdn2.sportngin.com/attachments/photo/825f-141969332/if-i-were-a-parent-sportsmanship_large.jpg"
                 alt=""
                  height="100%"/>
                <img src="https://res.cloudinary.com/little-league/image/upload/c_limit,dpr_auto,w_1536,q_auto,c_fill,g_face/v1520559630/Sportsmanship.jpg"
                  alt=""
                  height="100%"/>
                <img src="https://activeforlife.com/content/uploads/2014/10/girls-soccer-sportsmanship.jpg"
                  alt=""
                  height="100%"/>
                <img src="https://www.robertglazer.com/wp-content/uploads/2018/06/FF_130_GettyImages-471019485.jpg"
                  alt=""
                  height="100%"/>
              </Carousel>
            </Grid>
          </Grid>
        </Section>

        <Section xs={12} md={10} classes={{ background: classes.CalendarSection }}>
          <Heading>
            Events
          </Heading>
          <Events />
        </Section>

        <Section classes={{ background: classes.ArticleSection }}>
          <NewsFeed xs={10} mb={8} mt={4} backgroundColor="DarkGray" articles={ news }/>
        </Section>

        {/*}<Section classes={{ background: classes.Leagues }}>
          <Heading classes={{ text: classes.shadowText }}>
            Youth Soccer Leagues
          </Heading>
          <Pricing options={ leaguesAll } />
        </Section>*/}

        <Section classes={{ background: classes.SportsmanshipSection }}>
          <Grid container xs={10} spacing={8}>
            <Grid item xs={10} md={8} >
              <Table headers={ headers } rows={ rows } />
            </Grid>
            <Grid item xs={10} md={4} >
              <Carousel classes={{ card: classes.sportmanshipCard }} fade="true">
                <img src="https://cdn2.sportngin.com/attachments/photo/825f-141969332/if-i-were-a-parent-sportsmanship_large.jpg"
                  alt=""
                  height="100%"/>
                <img src="https://res.cloudinary.com/little-league/image/upload/c_limit,dpr_auto,w_1536,q_auto,c_fill,g_face/v1520559630/Sportsmanship.jpg"
                  alt=""
                  height="100%"/>
                <img src="https://activeforlife.com/content/uploads/2014/10/girls-soccer-sportsmanship.jpg"
                  alt=""
                  height="100%"/>
                <img src="https://www.robertglazer.com/wp-content/uploads/2018/06/FF_130_GettyImages-471019485.jpg"
                  alt=""
                  height="100%"/>
              </Carousel>
            </Grid>
          </Grid>
        </Section>


        <Section classes={{ background: classes.SocialSection }} xs={12} md={11}>
          <Heading>
            #TCSoccer
          </Heading>
          <Subtitle>
            THE BIG PICTURE IN SMALL PHOTOS - SHARE YOUR FAVORITE MOMENTS WITH US!
          </Subtitle>
          <InstaCarousel account="tandcsports" numberOfMediaElements={6} />
        </Section>

      </Box>
    );
  }
}


export default withStyles(styles)(App);
