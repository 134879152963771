import React, { Component } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import Heading from './Heading';
import Subtitle from './Subtitle';


const styles = (theme) => createStyles({
  overlay: {
    position: "relative",
  },
  background: {
    position: "absolute",
    top: 0,
    left:0,
    right:0,
    bottom:0,
    height: "100%",
    width: "100%",
    overflow: "hidden",
    opacity: "0.8",
    transition: "background 1.5s linear",
    zIndex: -1,
  },

});



class Section extends Component {
  render() {
    const { classes, children, ...props } = this.props;

    return (
      <Box p={2} { ...props } classes={{ root: classes.overlay }}>
        <Box classes={{ root: classes.background }} />
        {
          React.Children.map(children, (child) => (
            <Grid container { ...props }>
              <Grid item xs={12}>
                { React.cloneElement(child, {}) }
              </Grid>
            </Grid>
          ))
        }
      </Box>
    );
  }

}

export { Heading, Subtitle };

export default withStyles(styles)(Section);
