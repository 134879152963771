import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { createStyles, withStyles } from '@material-ui/core/styles';

import DescriptionIcon from '@material-ui/icons/Description';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import ImageIcon from '@material-ui/icons/Image';
import WorkIcon from '@material-ui/icons/Work';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import EventIcon from '@material-ui/icons/Event';

import HeroImage from '../Hero/HeroImage';

const scheduleLinks = [
  {
    href: "https://cdn1.sportngin.com/attachments/document/d3e3-2117589/6U_Spring_20_Schedule.xlsx#_ga=2.174903146.950132219.1592963290-249538973.1592963289",
    title: "6U Spring 20 Schedule",
    subtitle: "",
  },
  {
    href: "https://cdn1.sportngin.com/attachments/document/940a-2117590/8U_SP20_Schedule.xlsx#_ga=2.183242734.950132219.1592963290-249538973.1592963289",
    title: "8U Spring 20 Schedule",
    subtitle: "",
  },
  {
    href: "https://cdn1.sportngin.com/attachments/document/a0e8-2117593/10U_SP20_Schedule.xlsx#_ga=2.183242734.950132219.1592963290-249538973.1592963289",
    title: "10U Spring 20 Schedule",
    subtitle: "",
  },
  {
    href: "https://cdn3.sportngin.com/attachments/document/18d5-2117638/12U_SP20_Schedule.xlsx#_ga=2.150874110.950132219.1592963290-249538973.1592963289",
    title: "12U Spring 20 Schedule",
    subtitle: "",
  },
  {
    href: "https://cdn4.sportngin.com/attachments/document/b34f-2113652/T_C_2020_Spring_Softball_Schedule_rev__5_dated_2-18-20.pdf#_ga=2.150874110.950132219.1592963290-249538973.1592963289",
    title: "Spring 2020 Master Schedule",
    subtitle: "",
  },
];


const styles = (theme) => createStyles({
  root: {
    width: '100%',
  },
  button: {
    width: '100%',
  },
  container: {
    width: '100%',
  }
});


function SchedulePage(props) {
  const { classes } = props;
  const [checked, setChecked] = React.useState([0]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return(
    <Box align="center">

      <HeroImage
        source="https://tcsports.staging.abccopy.co/includes/images/bkgd/soccer_field.jpg"
        title="T&C Soccer"
        height="sm"
      />

      <Box pt={8} mb={24}>
        <Grid container item xs={12} md={10} spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography variant="h2">GAME SCHEDULES</Typography>
            <Typography variant="subtitle1">Calendar View</Typography>
            <List className={classes.root} style={{ width: "100%", marginLeft:0,
    marginRight:0 }}>
              { scheduleLinks?.map( (link) => (
                <Button classes={{ root: classes.button }} style={{ width: "100%" }}>
                  <ListItem  alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar>
                        <DescriptionIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={ link.title } secondary={ link.subtitle } />
                  </ListItem>
                </Button>
              ))}
            </List>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h2">GAME SCHEDULES</Typography>
            <Typography variant="subtitle1">Excel Files</Typography>
            <List classes={{ container: classes.root }} style={{ width: "100%", marginLeft:0,
    marginRight:0 }}>
              { scheduleLinks?.map( (link, key) => (
                <Button classes={{ root: classes.button }} style={{ width: "100%" }}>
                  <ListItem classes={{ container: classes.container }} style={{ width: "100%" }} containerProps={{ classes: { root: classes.container }  }}>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        //checked={checked.indexOf(key) !== -1}
                        tabIndex={-1}
                        disableRipple
                      />
                    </ListItemIcon>
                    <ListItemText primary={ link.title } secondary={ link.subtitle } />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="delete">
                        <DescriptionIcon />
                      </IconButton>
                      <IconButton edge="end" aria-label="delete">
                        <EventIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </Button>
              ))}
              <Button classes={{ root: classes.button }} style={{ width: "100%" }}>
                <ListItem classes={{ container: classes.container }} style={{ width: "100%" }} containerProps={{ classes: { root: classes.container }  }}>
                  <ListItemText secondary="Download Selected Files" />
                </ListItem>
              </Button>
            </List>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default withStyles(styles)(SchedulePage);
