const defaultPalette = {
  primary: {
    light: '#d8d8db',
    main: '#002f86',    //ToDo pull tcBlue from universal CSS
    mainGradient: "linear-gradient(to left, #002f86 20%, #002f86d0 100%);",
    dark: '#002f86',
    contrastText: '#fff',
  },
  secondary: {
    light: '#ff8671',
    main: '#eb0028',
    dark: '#ba000d',
    contrastText: '#fff',
  },
  tcBlue: {
    light: '#606db2',
    main: '#002f86',    //ToDo pull tcBlue from universal CSS
    dark: '#002f86',
    contrastText: '#fff',
  },
  tcRed: {
    light: '#ff8671',
    main: '#eb0028',    //ToDo pull tcBlue from universal CSS
    dark: '#eb0028',
    contrastText: '#fff',
  },
  tcYellow: {
    light: '#ffcd00',
    main: '#ffcd00',    //ToDo pull tcBlue from universal CSS
    dark: '#ffcd00',
    contrastText: '#002f86',
  },
}


export default defaultPalette;
