import React, { Component } from "react";
import Box from '@material-ui/core/Box';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Slider from "react-slick";


const styles = (Theme) => createStyles({
  root: {
  },
  card: {
  },
  child: {
    height: "100%"
  },
});


class Carousel extends Component {
  static defaultProps = {
    lazyLoad: 'ondemand',
    dots: true,
    infinite: true,
    speed: 900,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    cssEase: "linear",
    centerMode: true,
  }

  render() {
    const { classes, children, ...props } = this.props;

    /*return (
      <Box { ...props }>
        <Slider {...settings}>
          {
            children && React.Children.map(children, child => {
              return ( React.cloneElement(child));
            })
          }
        </Slider>
      </Box>
    );*/

    return (
      <Box { ...props }>
        <Slider { ...props }>
          {
            children && React.Children.map(children, child => {
              return (
                <Box className={ "item" } classes={{ root: classes.card }}>
                  { React.cloneElement(child, {classes: {...child.props.classes, root: classes.child}, styles: {height:"100%"}} )}
                </Box>
              );
            })
          }
        </Slider>
      </Box>
    );
  }
}

export default withStyles(styles)(Carousel);
