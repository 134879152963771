import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import GMap from '../GMap';


const styles = (theme) => createStyles({
  root: {

  },
  mapBox: {
    height: "100%",
    width: "100%",
    position: "relative",
  },
  mapOverlayBottom: {
    position: "absolute",
    bottom: "0",
    left: "0",
    right: "0",
    marginLeft: "4rem",
    marginRight: "4rem",
    marginBottom: "1.5rem",
  },
  OverlayPaper: {
    width: "100%",
  }
});

class MapPanel extends Component {

  propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  render() {
    const { children, value, index, classes, src, ...other } = this.props;

    return (
      <Box
        classes={{ root: classes.mapBox }}
        role="tabpanel"
        hidden={ value !== index }
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        { ...other }
      >
        <GMap
          src={ src }
          width="100%"
          height="100%"
          frameBorder="0"
          allowFullScreen=""
        />
        <Box classes={{ root: classes.mapOverlayBottom }}>
            <Paper classes={{ root: classes.OverlayPaper }}>
              { value === index && children }
            </Paper>
        </Box>
      </Box>
    );
  }
}

export default withStyles(styles)(MapPanel);
