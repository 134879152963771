import React, { Component } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import StoryCard from '../Card/StoryCard';

const styles = (theme) => createStyles({
  root: {
    flexGrow: 1,
  },
  media: {
    height: "500px",
    objectFit: "cover",
  },
  control: {
  },
});

class NewsFeed extends Component{

  render() {
    const { articles, classes, ...props } = this.props;

    return (
      <Box { ...props }>
        <Grid container classes={{ root: classes.root }} spacing={2} justify="center" { ...this.props }>
          { articles && articles.map( (article, key) =>
            <Grid key={1} item xs={12} md={4}>
              <StoryCard
                classes={{ media: classes.media }}
                image={ article.image }
                title={ article.title }
                subtitle1={ article.subtitle1 }
                subtitle2={ article.subtitle2 }
                button={ article.button }
                key={ key }
              />
            </Grid>
          )}
        </Grid>
      </Box>
    );
  }
}

export default withStyles(styles)(NewsFeed);
