import * as React from 'react';
import withInstagramFeed from 'origen-react-instagram-feed';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';
import compose from 'recompose/compose';
import InstagramCard from '../../Card/InstagramCard';
import Box from '@material-ui/core/Box';
import Carousel from 'react-material-ui-carousel'

const styles = () => ({
  wrapper: {},
  image: {
    width: '100%',
    height: '100%',
  },
});

export type Props = {
  media?: Array<{
    displayImage: string,
    id?: string,
    postLink?: string,
    accessibilityCaption?: string,
  }>,
  account: string,
  classes: { [$Keys<$Call<typeof styles>>]: string },
  status: 'completed' | 'loading' | 'failed',
};



const InstaCarousel = ({ classes, media, account, status}: Props) => {
  return (
    <Box  justifyContent="center">
      <Carousel>
        <Grid container spacing={1} p={1} className={classes.wrapper}>
          {media &&
            status === 'completed' &&
            media.map(({ displayImage, id, postLink, accessibilityCaption }) => (
              <Grid item xs={12} sm={4} md={2} key={id || displayImage}>
                <Button fullWidth={ true }
                  href={postLink || `https://www.instagram.com/${account}/`}
                >
                  {/*<img
                    src={displayImage}
                    alt={accessibilityCaption || 'Instagram picture'}
                    className={classes.image}
                  />*/}
                  <InstagramCard image={ displayImage } simple={ true }/>
                </Button>
              </Grid>
            ))}
          {status === 'loading' && <p>loading...</p>}
          {status === 'failed' && <p>Check instagram here</p>}
        </Grid>
      </Carousel>
    </Box>
  );
};

export const InstaGrid = ({ classes, media, account, status}: Props) => {
  return (
    <div>




    <Grid container spacing={3} className={classes.wrapper}>
      {media &&
        status === 'completed' &&
        media.map(({ displayImage, id, postLink, accessibilityCaption }) => (
          <Grid item xs={12} sm={4} md={2} key={id || displayImage}>
            <ButtonBase
              href={postLink || `https://www.instagram.com/${account}/`}
            >
              {/*<img
                src={displayImage}
                alt={accessibilityCaption || 'Instagram picture'}
                className={classes.image}
              />*/}
              <InstagramCard image={displayImage} />
            </ButtonBase>
          </Grid>
        ))}
      {status === 'loading' && <p>loading...</p>}
      {status === 'failed' && <p>Check instagram here</p>}
    </Grid>
    </div>
  );
};

InstaCarousel.defaultProps = {
  media: undefined,
};

export default compose(
  withInstagramFeed,
  withStyles(styles),
)(InstaCarousel);
