import React, { Component } from 'react';
import EmailIcon from '@material-ui/icons/Email';
import { withStyles, createStyles } from '@material-ui/core/styles';

import TextBox from '../TextBox';


const styles = (theme) => createStyles({
  root: {
  },
});


class Email extends Component {

  render() {
    const { classes, white } = this.props;

    return(
      <TextBox label="Your Email Address"
        helperText=""
        id="email"
        variant="outlined"
        white={ white }
        startAdornment={ <EmailIcon /> }
        classes={{ root: classes.root }}
      />
    );
  }

}

export default withStyles(styles)(Email);
