import React from 'react';
//import { withTheme } from '@material-ui/core/styles';
import { styled } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const RegisterButton = styled(Button)({
  background: 'primary',
  border: 0,
  borderRadius: '8px',
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  height: 48,
  padding: '0 30px',
  margin: '10px',
  fontSize: '2rem',
});


export default function StyledComponents() {
  return <RegisterButton color="secondary" variant="contained" href="https://www.tandcsports.org/tcregistration">Register</RegisterButton>;
}
